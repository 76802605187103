import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ConfigService } from './services/config.service';
import { AppConfig } from './models/app.config';

export interface ConfigResponse {
  embedUrl: string;
  embedToken: string;
  reportId: string;
  isUserAuth:boolean;
  // Id: string;
  // EmbedUrl: string;
  // EmbedToken: {
  //   Token: string;
  // };
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'msal-angular-tutorial';
  config!: AppConfig;
  constructor(private configService: ConfigService) {}

  ngOnInit(): void {
  }
}