<div class="containerHorizontal" >
    <div>
        <div>
            <div class="message">{{message}}</div>
        </div>
          
       
    </div>
   
   
</div>

    
