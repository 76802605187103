<div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
    <div class="example-box" *ngFor="let node of nodes" cdkDrag>
      <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
      {{node.name}}
    </div>
</div>


   
<div class="buttons">
    <button mat-stroked-button color="warn" (click)="save()"  value="Submit" >Cancel</button>
    <button mat-stroked-button color="primary" (click)="save()"  value="Submit" >Save</button>
</div>