import { Component, EventEmitter, Input, Output } from '@angular/core';
import {CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray} from '@angular/cdk/drag-drop';
import {NgFor} from '@angular/common';
import { SubjectArea } from 'src/app/models/subject-area';
import { HttpService } from 'src/app/services/http.service';
import { Router } from '@angular/router';
import { TreeSortNode, TreeSortRequest } from 'src/app/models/TreeSortNode';
import { TreeHelperServiceService } from 'src/app/services/tree-helper-service.service';

@Component({
  selector: 'app-node-drag-sort',
  templateUrl: './node-drag-sort.component.html',
  styleUrls: ['./node-drag-sort.component.scss']
})
export class NodeDragSortComponent {

  constructor(public treeHelperService: TreeHelperServiceService, public httpService: HttpService, private _router:Router) {

  }
  _nodes!: any[];
  get nodes(): any[] {
      return this._nodes;
  }

  @Input() set nodes(value: any[]) {
      const cleanValues = value.filter(x => x.id !== 'add');

      this._nodes = cleanValues;
  }



  @Output() nodeOrdersUpdated = new EventEmitter<any[]>();

  
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.nodes, event.previousIndex, event.currentIndex);
  }

  saveOld(){
    const nodeIds = this.nodes.map(x => x.id);
    const filterednodes = nodeIds.filter(x => this.isValidGUID(x)) as string [];
    this.httpService.SortSubjectAreas(filterednodes).subscribe(x => {
      this.nodeOrdersUpdated.emit(this.nodes);
      // this._router.navigate(['manage']).then(() => {
      //   window.location.reload();
      //   });
    });

    
  };

  
  save(){
   const treeSortNodeRequest: TreeSortRequest = {} as TreeSortRequest;
   treeSortNodeRequest.nodes = this.nodes.map(node => {
    let nodeTypee = '';
    // if(this.isSubjectAreaNode(node))
    // nodeTypee = 'SubjectArea';
    if(this.isCategoryNode(node))
    nodeTypee = 'Category';
    else if(this.isSubCategoryNode(node))
    nodeTypee = 'SubCategory';
    else if(this.isSubCategoryTwoNode(node))
    nodeTypee = 'SubCategoryTwo';
    else if(this.isDriveReportNode(node))
    nodeTypee = 'Report';
    else
    nodeTypee = 'SubjectArea';


    const treeSortNode: TreeSortNode = {nodeType: nodeTypee, nodeId: node.id};
    return treeSortNode;
   });

   this.treeHelperService.SortNodes(treeSortNodeRequest).subscribe(x => {
    this.nodeOrdersUpdated.emit(this.nodes);
  });
    
  };



isValidGUID(str: string) {
  // Regex to check valid
  // GUID 
  let regex = new RegExp(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/);

  // if str
  // is empty return false
  if (str == null) {
      return "false";
  }

  // Return true if the str
  // matched the ReGex
  if (regex.test(str) == true) {
      return "true";
  }
  else {
      return "false";
  }
}


isSubjectAreaNode(node: any) {
  if(node.hasOwnProperty('categories'))
    return true;
  return false;
}

isCategoryNode(node: any) {
  if(node.hasOwnProperty('subCategories'))
    return true;
  return false;
}

isSubCategoryNode(node: any) {
  if(node.hasOwnProperty('subCategoryTwos'))
    return true;
  return false;
}

isSubCategoryTwoNode(node: any) {
  if(node.hasOwnProperty('subCategoryId') &&
  node.hasOwnProperty('driveReports')
  )
    return true;
  return false;
}

isDriveReportNode(node: any) {
  if(node.hasOwnProperty('powerBiReportId'))
    return true;
  return false;
}

isAddNewSubjectAreaNode(node: any) {
  if(node.id == 'add')
    return true;
  return false;
}

}
