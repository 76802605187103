<div>
  <mat-chip-grid  >
    <mat-chip-row *ngFor="let option of selectedOptions" (removed)="remove(option)">
      {{option?.name}}
      <!-- <button matChipRemove [attr.aria-label]="'remove ' + option.name">
        <mat-icon>cancel</mat-icon>
      </button> -->
    </mat-chip-row>
  </mat-chip-grid>
  <label><span *ngIf="required" style="color: red">*</span> {{labelName}}</label>
  <mat-form-field class="field" appearance="outline">

    <!-- <div>
      <input type="text"
        placeholder="Enter {{labelName}}"
        matInput
        [formControl]="myControl"
        [matAutocomplete]="auto"
        (keyup)="onChangeEvent($event)">

        <button *ngIf="isNewEntry()" (click)="addNew()" class="addButton" mat-raised-button>Add</button>
    </div> -->
   
    <div>

      <mat-toolbar>
 

        <input type="text"
        placeholder="Enter {{labelName}}"
        matInput
        [formControl]="myControl"
        [matAutocomplete]="auto"
        (keyup)="onChangeEvent($event)">
  
        <button *ngIf="isNewEntry()" (click)="addNew()" class="addButton" mat-raised-button>Add</button>
      </mat-toolbar>
      
    </div>
           
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this)"  (optionSelected)="onSelectionChange($event)">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option.id">
        <div (click)="optionClicked($event, option);">
          <mat-checkbox [checked]="option.selected" (change)="toggleSelection(option)" >
            {{option.name}}
          </mat-checkbox>
        </div>
        
      </mat-option>
    </mat-autocomplete>
    <mat-error >{{labelName}} Required</mat-error>
  </mat-form-field>
</div>





