import { environment } from "src/environments/environment";

// Success Image element
const successElement = document.createElement('img');
successElement.className = 'status-img';
successElement.src = '/assets/success.svg';

// Error Image element
const errorElement = document.createElement('img');
errorElement.className = 'status-img';
errorElement.src = '/assets/error.svg';

// Endpoint to get report config
//const reportUrl = 'https://aka.ms/CaptureViewsReportEmbedConfig';
//const reportUrl = 'https://localhost:44366/api/report';
// const reportUrl = 'https://localhost:5001/api/report';
//const reportUrl = 'https://gallagherdriveapi-test.ajg.com/api/report';
//const reportUrl = 'https://gallagherdriveapi-dev.ajg.com/api/report';
//const reportUrl = `${environment.apiUrl}/api/report`

const errorClass = 'error';
const successClass = 'success';

// To show / hide the report container
const hidden = 'hidden';

// To position the display message
const position = 'position';

const token = "H4sIAAAAAAAEAB2UxQ7sCBIE_-VdPZKxDSPNwczMvpmhzWyv9t-3Z--pUioUlf_5Y6VPP6XFn7__7CwSv0Ot-OnNpiwDbuoOnXxhFxoBE24ShhwaZ2685ciQVcTnfqwTelUpctbEIquWGuo0Ab0YK3CocIOs9Pw-sMnE5nW6QFvfnFH_KEgjXvoACAPSrt8VYXXpPZdDLY_0NNCtf8fA5WEpB6rNNImmGe94m3CWYQpQv0pjPkmmszfbOeJKADEmm6boFI0MtYvc45xyD2EszJ0ZNnAORrr0vf2SJ2ensZTXAPoUcUaUFyB9lAI0v5xrRG0hMGC6mJslUzSIj08tu--xGXh93P1VIMO1lZPha9jrOd2iv3jsOXED8Xbm9ySOASZ-cKBU0oPHXOvMq9Xv-SRMxFFMXiRq7-fCPKKUEJ83_7HUKY86NEBcNMrsHDwclMbiOPED3b18zMHHtl1S8mLHVuatGTYFHhkPreclBJ8rS1pQVSEzgO_p6RggZKcAOZNsl-QHWCptUlK1jgnEwoUWbrmSg7hSnYj7oNIFMrHEVmJuu_kwutAtJUBK-Y0gThzwn3VrxC_XLQwVvyiV4in28IuAwDJx_5RophlYabxQTDdcUGgpvefs-tNROt6_V0xV928frrMPfZjFx8wSInQGdtLiWkD41XZEL_IAVJ3h9XKPFlU2TUsDrk7SGebiRiCnZBADK007bEyv32cUSNqFCuf16Cs4X_V8O22Zk_Y2ezi7EiDaBk2KorHnAF94pR3g2joRup3qNXBs90VtcuSSBdVGqz8BJprpGH5WV19GIus5OEI1-Wv1daKt9ykrQ1qL7bWufN_47OP3zexjmWrKy-Q3nLkfyvodqxA5teeFrE2r6C8eZ4tvNWeLZl6hDsKAt7hukWUg6N2AdyqnQlVY0B-O5XhJW25LHjPVnRXxnGPXm5kBnJijFZUYs-ADijPdCySkAg-WzJU_f_1h12feJ7V8fu_Yr0RQsNk0f35WCt-vx350EzGviLBL0K9OG0MUE_a8KO_nBehQJG4TqHifjBkalYorSNYkNhKGWcotrFK8fSfMSiTREX11dW8IfEU7hMQzkAOr6_KQUBAk6Y2Q5R7HqBUTyrEo0hIf7Vu-Za3dIAbILtCx2hTVei5UvS4qWJbaLnxK1oa336SQpvyE0G8VG9f7tHvadZw9obxCuZVZhFhMSzdwzhoJewVqpe9mUUUsX10LyLtledW1RWbstnRo1kO5cw88ne0EZHgmzjg9Asl6CIFji5_BoNDaRYQJg-psFunMs4dyGI8H8j-knljCwLilQjg-azg3bVSY0Xwsk1KaGKb_-edfzM_clKsc_Cinr9ocMRPAeDH59fwtf1Yu9f9TbluP6X6s5S_2tS3wtBY2sDr-M23IspfSfC_sQ7Oa6nmYWFsU5lMrT40EM3rK3RbSN7E040EuUzdrj93gU5RpTQaBlM46bnMlxruSs7eeTK78xoQ4NEyjBdmPFmzarr2-pvD6MRcLn9B6ElTHu9H5lXnuOmx4_wI2Shoh6hjAw3pWJrsH3dRpaB6LXIHhmPsQOfcuQ-bnQwAtilOxQ0N4vme6mGC9nkgTmAI5pNCQzeYsW1tFQBqhr3F9TtSlCjlqZbthG69u1N8lJQAUKeHk0KGnbt--smAlGFRIRcdV5pbChgGpCTYjoKlaQXVzs4hbYCb_9oIiJN4La4jzo2smcpUNk0DZEP5q1n8x__d_TrLpYIIGAAA=.eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLU5PUlRILUNFTlRSQUwtRi1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZXhwIjoxNjc0MzE1NDE5LCJhbGxvd0FjY2Vzc092ZXJQdWJsaWNJbnRlcm5ldCI6dHJ1ZX0=";
const tokenId = "7fb8ac32-8e0d-4855-9f1f-9f41fec6d93a";
const embedUrl = "https://app.powerbi.com/reportEmbed?reportId=40d736fe-efc2-4a1a-8a7f-b431b469fd54&groupId=5f41d015-9914-4d2f-885b-cc330e68e58a&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLU5PUlRILUNFTlRSQUwtRi1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d";

export { errorClass, errorElement, hidden, position, successClass, successElement, token, tokenId, embedUrl };
