import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, lastValueFrom } from 'rxjs';
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types"
import { AppConfig } from '../models/app.config';
import { SubjectArea } from '../models/subject-area';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class MegamenuService {
  isInteractable = true;
  headerHoveredLast = false;
  mobileHeaderVisible: boolean = false;

  baseUrl: string;
  config!: AppConfig;
  megamenuLoading = true;

  userAssignedAdGroupsChange: Subject<any[]> = new Subject<any[]>;
  userLatestSubjectAreasChange: Subject<SubjectArea[]> = new Subject<SubjectArea[]>;
  userLatestSubjectAreasObservable: Observable<SubjectArea[]> = new Observable<SubjectArea[]>;
  
  userAzureAdGroups?: string [];

  username = '';
  adminAdGroupName = 'u-GallagherDriveInteractivePortal-Admin';

  

  constructor(private http: HttpClient, private configService: ConfigService) {
    this.config = this.configService.readConfig() as AppConfig;
    this.baseUrl = `${this.config.apiUrl}/api`;
   }

  getAccessTokenAndCallGraphAPI(accessToken: string, username: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: 'Bearer '+ accessToken
      })}

    this.http.get(`https://graph.microsoft.com/v1.0/users/${username}/memberOf?$select=id,displayName&$top=999`,httpOptions).toPromise().then(result=>{
      this.userAssignedAdGroupsChange.next((result as any).value);
    });
    
  }

   isAdmin() {
    let isAdmin = false;
   
    if(this.userAzureAdGroups) {
            // Used for testing with a role we do have at the time: isAdmin = this.userAzureAdGroups.includes('u-gts-planview-user-pilot');
      isAdmin = this.userAzureAdGroups.includes(this.adminAdGroupName);
    }
   return isAdmin;
  }

  getMegaMenuSubjectAreaForAdGroupNames(adGroupNames?: string[]): Observable<SubjectArea[]> {

    this.userAzureAdGroups = adGroupNames;
    let requestUrl = `${this.baseUrl}/subjectarea/megamenu/adgroup/names`;
    const observable = this.http.post<SubjectArea[]>(requestUrl,adGroupNames);
    
    observable.subscribe(data => { 
      const now = new Date();
      localStorage.setItem("MEGAMENU_SUBJECTAREAS_BYNAME_LAST_CACHE_DATE", now.toString() );
      localStorage.setItem("MEGAMENU_SUBJECTAREAS_BYNAME_VALUE", JSON.stringify(data) );
      localStorage.setItem("USERS_AZURE_ADGROUPS", JSON.stringify(adGroupNames) );
      this.userLatestSubjectAreasChange.next(data);
    });
   
    return observable;
  }
}
