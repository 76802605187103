import { Component } from '@angular/core';

@Component({
  selector: 'app-add-report-main',
  templateUrl: './add-report-main.component.html',
  styleUrls: ['./add-report-main.component.scss']
})
export class AddReportMainComponent {

}
