import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ReportType } from 'src/app/Enums/ReportType';
import { BiGroupDto, BiReportDto } from 'src/app/models/bi';
import { Category } from 'src/app/models/category';
import { Country } from 'src/app/models/country';
import { DriveReport } from 'src/app/models/drive-report';
import { DriveReportResponse } from 'src/app/models/drive-report-response';
import { SubCategory } from 'src/app/models/sub-category';
import { SubjectArea } from 'src/app/models/subject-area';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-add-report-modal',
  templateUrl: './add-report-modal.component.html',
  styleUrls: ['./add-report-modal.component.scss']
})
export class AddReportModalComponent {
  @Output() saveSuccess = new EventEmitter<boolean>();
  subjectAreas: SubjectArea[] = [];
  categories: Category[] = [];
  subCategories: SubCategory[] = [];
  reports: DriveReport[] = [];
 
  countries: string[] = [];
  reportIdDropdownValues: BiReportDto[] = [];
  tempImageBase64: string = '';

  editMode = false;
  selectedReportTabIndex: number = 0;

 urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  //groupIdDropdownValues: BiGroupDto[] = [];
  private _groupIdDropdownValues!: BiGroupDto[];
  set groupIdDropdownValues(val) {
    this._groupIdDropdownValues = val 

    var t = this.reportForm.controls.workSpace.value;
    this.reportForm.controls.workSpace.setValue(t);
    this.reportForm.controls.workSpace.markAsDirty();
  }
  get groupIdDropdownValues() {
    return this._groupIdDropdownValues
  }

  constructor(public httpService: HttpService, private _sanitizer: DomSanitizer, private _snackBar: MatSnackBar, @Inject(MAT_DIALOG_DATA) public data: DriveReportResponse) {} 

  reportForm = new FormGroup({
    countries: new FormControl(''),
    subjectArea: new FormControl('', [Validators.minLength(2), Validators.required]),
    subjectAreaDescription: new FormControl('', [Validators.required]),
    category: new FormControl('', [Validators.minLength(2), Validators.required]),
    subCategory: new FormControl(''),
    workSpace: new FormControl(''),
    reportId: new FormControl(''),
    reportName: new FormControl('', [Validators.required]),
    reportUrl: new FormControl('', [Validators.pattern(this.urlRegex)]),
    reportUploadData: new FormControl(''),
    image: new FormControl(''),
  });
  
  formValid() {
 if(this.editMode)
 return true;

    let valid = (this.reportForm.controls.subjectArea.dirty && this.reportForm.controls.subjectArea.valid) &&
    (this.reportForm.controls.category.dirty && this.reportForm.controls.category.valid) &&
    (this.reportForm.controls.reportName.dirty && this.reportForm.controls.reportName.valid) &&
    (this.reportForm.controls.subjectAreaDescription.dirty && this.reportForm.controls.subjectAreaDescription.valid)

    let reportAreaValid = false;
    switch(this.selectedReportTabIndex) { 
      case 0: { 
        reportAreaValid = (this.reportForm.controls.reportId.dirty && this.reportForm.controls.reportId.valid) &&
                          (this.reportForm.controls.workSpace.dirty && this.reportForm.controls.workSpace.valid);
      
        break; 
      } 
      case 1: { 
        reportAreaValid = (this.reportForm.controls.reportUploadData.dirty && this.reportForm.controls.reportUploadData.valid);
        break; 
      } 
      case 2: { 
        reportAreaValid = (this.reportForm.controls.reportUrl.dirty && this.reportForm.controls.reportUrl.valid);
        break;  
     } 
      default: { 
         //statements; 
         break; 
      } 
   } 

   return valid && reportAreaValid;
  }

  ngOnInit() {
    try {
      //get values here
      this.httpService.getSubjectAreas('').subscribe(data => {
        this.subjectAreas = data as SubjectArea[];
  
        this.subjectAreas.forEach((subjectArea) => {
         subjectArea.safeResourceUrl = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' 
         + subjectArea.image);
  
         subjectArea.isNew = false;
        });
  
        if(this.editMode) {
          this.initializeFormForEdit();
        }
          
      });
    } catch (error: any) {
      // Prepare status message for Embed failure
   
      const displayMessage = `Failed to fetch config for report. Status: ${error.statusText} Status Code: ${error.status}`;
      console.error(displayMessage);
      return;
    }
  
    this.httpService.getBiGroups().subscribe(data => {
      this.groupIdDropdownValues = data as BiGroupDto[];
  
    });
  
    this.httpService.getCountries().subscribe(data => {
  
      const temp = data as Country[];
  
      this.countries = temp.map(function(country) {
        return country['name'];
      });
  
    });


    this.reportForm.controls.workSpace.valueChanges.subscribe(selectedWorkspaceId => {
      if(selectedWorkspaceId)
        this.getReportsForWorkSpace(selectedWorkspaceId);
    });

    this.reportForm.controls.subjectArea.valueChanges.subscribe(selectedSubjectAreaId => {
      if(selectedSubjectAreaId)
        this.getCategoriesForSelectedSubjectArea(selectedSubjectAreaId);
   
       
    });

    this.reportForm.controls.category.valueChanges.subscribe(selectedCategoryId => {
      if(selectedCategoryId)
        this.getSubCategoriesAndReportsForSelectedCategory(selectedCategoryId);
   
       
    });
  }

  initializeFormForEdit() {
    this.reportForm.controls.subjectArea.setValue(this.data.subjectAreaId);
    this.reportForm.controls.category.setValue(this.data.categoryId);
    this.reportForm.controls.subCategory.setValue(this.data.subCategoryId);
    this.reportForm.controls.subjectAreaDescription.setValue(this.data.description);
    this.reportForm.controls.reportName.setValue(this.data.reportName);
    this.reportForm.controls.workSpace.setValue(this.data.workSpaceId);
    this.reportForm.controls.reportId.setValue(this.data.id);
    this.reportForm.controls.reportUploadData.setValue(this.data.reportUploadData);
    this.reportForm.controls.reportUrl.setValue(this.data.reportUrl);

    this.reportForm.controls.subjectArea.disable();
    this.reportForm.controls.category.disable();
    this.reportForm.controls.subCategory.disable();

    if(this.data.image)
      this.updateImageBase64String(this.data.image);

    for (const field in this.reportForm.controls) { // 'field' is a string

      this.reportForm.get(field)?.markAsDirty(); // 'control' is a FormControl  
    
    }


    // var tt = this.subjectAreas.find(x => x.id == this.data.subjectAreaId)
    // this.reportForm.controls.subjectArea.setValue( JSON.stringify( this.subjectAreas.find(x => x.id == this.data.subjectAreaId)));
    // const t =  this.reportForm.controls.subjectArea.value;
    // this.subjectAreas.find(x => {x.id == this.data.subjectAreaId});

  }

  addNewSubjectArea(subjectAreaname: string) {
    let newSubjectArea = {
      name: subjectAreaname,
      description: this.reportForm.controls.subjectAreaDescription.value ? this.reportForm.controls.subjectAreaDescription.value : null,
      image: this.reportForm.controls.image.value ? this.reportForm.controls.image.value : null,
    } as SubjectArea;
    newSubjectArea.sequence = this.subjectAreas ? this.subjectAreas.length : 0;
    
    this.httpService.CreateSubjectArea(newSubjectArea).subscribe(Id => {
     
      const newSubjectArea = {
        id:Id,
        name:subjectAreaname,
       
      } as SubjectArea;
      this.subjectAreas.push(newSubjectArea);

      this.reportForm.controls.subjectArea.setValue(Id);
      
      });
  
  }

  addNewCategory(categoryName: string) {
    let newCategory = {name: categoryName} as Category;
    newCategory.sequence = this.categories ? this.categories.length : 0;

    newCategory.subjectAreaId = this.reportForm.controls.subjectArea.value as string;

    this.httpService.CreateCategory(newCategory).subscribe(Id => {
      
     const newCategory = {
      id:Id,
      name:categoryName,
      isNew: true
    } as Category;
    this.categories.push(newCategory);
    
    this.reportForm.controls.category.setValue(Id);

      });
  }

  addNewSubCategory(subCategoryName: string) {
    let newSubCategory = {name: subCategoryName} as SubCategory;
    newSubCategory.sequence = this.subCategories ? this.subCategories.length : 0;

    newSubCategory.categoryId = this.reportForm.controls.category.value as string;

    this.httpService.CreateSubCategory(newSubCategory).subscribe(Id => {

      const newSubCategory = {
        id:Id,
        name:subCategoryName,
        isNew: true
      } as SubCategory;
      this.subCategories.push(newSubCategory);
      
      this.reportForm.controls.subCategory.setValue(Id);
    });
  }

  getCategoriesForSelectedSubjectArea(subjectAreaId: string) {
    const subjectAreaWithId = this.subjectAreas.filter(x => x.id == subjectAreaId);
    if(subjectAreaWithId) {
      if(subjectAreaWithId[0].categories) {
        this.categories = subjectAreaWithId[0].categories;
      } else {
        this.categories = []
      }
      
    }

  }

  updateImageBase64String(base64String: any){
   this.tempImageBase64 = base64String;
   this.reportForm.controls.image.setValue(base64String);
   this.reportForm.controls.image.markAsDirty();
   }

   updateReportUploadBase64String(base64String: any){
   this.reportForm.controls.reportUploadData.setValue(base64String);
   this.reportForm.controls.reportUploadData.markAsDirty();
   }

  getSubCategoriesAndReportsForSelectedCategory(categoryId: string) {
    const categoryWithId = this.categories.filter(x => x.id == categoryId);
    if(categoryWithId) {
      if(categoryWithId[0].subCategories)
        this.subCategories = categoryWithId[0].subCategories;
      else 
        this.subCategories = [];
        

      if(categoryWithId[0].driveReports)
        this.reports = categoryWithId[0].driveReports;
      else 
        this.subCategories = [];
      
    }
  }


  getReportsForWorkSpace(workspaceId: string) {
    this.httpService.getBiReports(workspaceId).subscribe(data => {
      this.reportIdDropdownValues = data as BiReportDto[];
  
    });
  }

  selectedTabValue(event: MatTabChangeEvent ) {
    const labelName = event.tab.textLabel;
    const index = event.index;
    this.selectedReportTabIndex = index;

  }
  

  save() {
    let driveReport = null;
    
    switch(this.selectedReportTabIndex) { 
      case 0: { 
        driveReport ={
          id: this.reportForm.controls.reportId.value,
          workSpaceId: this.reportForm.controls.workSpace.value,
          name: this.reportForm.controls.reportName.value,
          reportType: ReportType.Drive
          //description: 'TestRep',
          //id: 'c47a1177-b3d7-47df-a16b-1ec831cb29ec'
        } as DriveReport;
        break; 
      } 
      case 1: { 
        driveReport ={
          name: this.reportForm.controls.reportName.value,
          reportUpload: this.reportForm.controls.reportUploadData.value,
          reportType:ReportType.Upload
        } as DriveReport;
        break; 
      } 
      case 2: { 
        driveReport ={
          name: this.reportForm.controls.reportName.value,
          url: this.reportForm.controls.reportUrl.value,
          reportType: ReportType.Url
        } as DriveReport;
        break;  
     } 
      default: { 
         //statements; 
         break; 
      } 
   } 

   let subCategories = null;
   if(this.reportForm.controls.subCategory.value) {
    subCategories = [
      {
        id: this.reportForm.controls.subCategory.value,
        // name: 'TestSub',
        // description: 'TestSub',
        driveReports: [
          driveReport]

      } as SubCategory]
   }

      let subjectArea = 
      {
        id: this.reportForm.controls.subjectArea.value,
        image: this.reportForm.controls.image.value,
        description: this.reportForm.controls.subjectAreaDescription.value,
        countries: [{name: 'Tunisia'} as Country],
        categories: [
                      {
                        id: this.reportForm.controls.category.value,
                        // name: 'TestCat',
                        // description: 'TestCat',
                        subCategories: subCategories,
                        driveReports: [
                          driveReport]

                      } as Category],
        

        
       
      } as SubjectArea

     


        // let subjectArea = 
        // {
        //   name: 'Test',
        //   description: 'Test',
        //   //id: this.reportForm.controls.reportId.value,
        //   //description: this.reportForm.controls.description.value,
        //   countries: [{name: 'Tunisia'} as Country],
        //   categories: [
        //                 {
        //                   //id: this.reportForm.controls.category.value,
        //                   name: 'TestCat',
        //                   description: 'TestCat',
        //                   subCategories: [
        //                     {
        //                       //id: this.reportForm.controls.subCategory.value,
        //                       name: 'TestSub',
        //                       description: 'TestSub',
        //                       driveReports: [
        //                         {
        //                           //id: this.reportForm.controls.subCategory.value,
        //                           name: 'TestRep',
        //                           description: 'TestRep',
        //                           id: 'c47a1177-b3d7-47df-a16b-1ec831cb29ec'
                                 
          
        //                         } as DriveReport]
      
        //                     } as SubCategory]
  
        //                 } as Category],
          
  
          
         
        // } as SubjectArea

        if(this.editMode)
        {
          subjectArea.name = this.subjectAreas.find(x => x.id == this.reportForm.controls.subjectArea.value)?.name as string;
          this.httpService.UpdateSubjectArea(subjectArea).subscribe(Id => {
            this.saveSuccess.emit(true);
            });
      
        }
      else {
        this.httpService.CreateSubjectArea(subjectArea).subscribe(Id => {
          this.saveSuccess.emit(true);
          });
      
      }
  }




  
  
}
