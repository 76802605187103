<form>
 

    <div>
      <label><span style="color: red">*</span> Country</label>
      <mat-form-field class="country-chip-list" appearance="outline">
      
        <mat-chip-grid #chipGrid aria-label="Fruit selection">
          <mat-chip-row *ngFor="let country of selectedCountries" (removed)="remove(country)">
            {{country}}
            <button matChipRemove [attr.aria-label]="'remove ' + country">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
        </mat-chip-grid>
        <input placeholder="Select Country" #countryInput [formControl]="countryCtrl"
          [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)"
          />
        <mat-autocomplete [formControl]="myControl" #auto="matAutocomplete" (optionSelected)="selected($event)">
          <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
            {{country}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </form>