<div *ngIf="breadCrumb" style="display: flex; flex-direction: row; margin-left: 100px;">
    <!-- breadCrumb: BreadCrumb = {subjectAreaName: 'Test Sub Area', categoryName: 'Test Category', subCategoryName: 'Test Sub Category', subCategoryTwoName: 'Test Sub Category 2', reportName: 'Test Report Name'} as BreadCrumb; -->


    <div *ngIf="breadCrumb.subjectAreaName" style="display: flex; flex-direction: row;">
        <div >
            {{breadCrumb.subjectAreaName}}
        </div>

        <div>
            /
        </div>
        
    </div>

    <div *ngIf="breadCrumb.categoryName" style="display: flex; flex-direction: row;">
        <div>
            {{breadCrumb.categoryName}}
        </div>

        <div>
            /
        </div>
    </div>

    <div *ngIf="breadCrumb.subCategoryName" style="display: flex; flex-direction: row;">
        <div>
            {{breadCrumb.subCategoryName}}
        </div>

        <div>
            /
        </div>
    </div>

    <div *ngIf="breadCrumb.subCategoryTwoName" style="display: flex; flex-direction: row;">
        <div>
            {{breadCrumb.subCategoryTwoName}}
        </div>

        <div>
            /
        </div>
    </div>

    <div *ngIf="breadCrumb.reportName" style="display: flex; flex-direction: row;">
        <div>
            {{breadCrumb.reportName}}
        </div>

        
    </div>

</div>
