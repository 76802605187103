

<div class="container">
  <div class="headerRow">
      <div *ngIf="editMode">Edit Report</div>
      <div *ngIf="!editMode">Add Report</div>
  </div>

  <div class="inputArea"> 
      <form [formGroup]="reportForm" class="inputForm">

        <div class="grid_container">
          <div><country-select [myControl]="reportForm.controls.countries" [allCountries]="countries" class="settingFieldHalfSize countrySelect"></country-select></div>
          <div></div>

          <div>
            <app-autocomplete-save (addNewEntry)="addNewSubjectArea($event)"  [myControl]="reportForm.controls.subjectArea" labelName="Subject Area" [options]="subjectAreas"></app-autocomplete-save>
          </div>
          <div> 
            <div>
              <label><span style="color: red">*</span> Description</label>
              <mat-form-field class="input"  appearance="outline" >
                
                <input formControlName="subjectAreaDescription" type="tel" matInput >
                <mat-error >Description is required</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div>
            <app-upload-images class="settingField" (base64StringUpdated)="updateImageBase64String($event)"></app-upload-images>
            <img  [src]="tempImageBase64" class="preview">
          </div>
          <div></div>


          <div> 
            <app-autocomplete-save (addNewEntry)="addNewCategory($event)"  [myControl]="reportForm.controls.category" labelName="Category" [options]="categories"></app-autocomplete-save>
          </div>
          <div>
            <app-autocomplete-save  (addNewEntry)="addNewSubCategory($event)" [myControl]="reportForm.controls.subCategory" labelName="Sub Category" [options]="subCategories"></app-autocomplete-save>
          </div>


          <div> 
            <div>
              <label><span style="color: red">*</span> Report Name</label>
              <mat-form-field class="input"   appearance="outline" >
                <input formControlName="reportName" type="tel" matInput >
                <mat-error >Report name is required</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div></div>

          
        </div>

        <mat-tab-group (selectedTabChange)="selectedTabValue($event)">
          <mat-tab label="Search from Drive"> 
            <div class="grid_container">
          
              <div>
                <div>
                  <label><span style="color: red">*</span> Work Space</label>
                  <mat-form-field class="input" appearance="outline"  >
                    <mat-icon matPrefix>cases</mat-icon>
                    <mat-select formControlName="workSpace"  >
                        <mat-option *ngFor="let biGroup of groupIdDropdownValues" [value]="biGroup.id" >{{biGroup.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                </div>
              </div>
              <div>
                <div>
                  <label><span style="color: red">*</span> Report Id</label>
                  <mat-form-field class="input"appearance="outline" >
                    <mat-icon matPrefix>cases</mat-icon>
                    <mat-select formControlName="reportId" >
                        <mat-option *ngFor="let biReport of reportIdDropdownValues" [value]="biReport.id">{{biReport.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>  
                </div>
              </div>
            </div>
            </mat-tab>
          <mat-tab label="File Upload"> 
            <div>
              <label><span style="color: red">*</span> Upload File</label>
              <mat-form-field class="input"  appearance="outline" >
                <app-file-uploader (base64StringUpdated)="updateReportUploadBase64String($event)"></app-file-uploader>
                <input type="tel" matInput >
              </mat-form-field>
            </div>
          </mat-tab>
          <mat-tab label="Web Link"> 
            <div>
              <label><span style="color: red">*</span> Web Link</label>
              <mat-form-field class="input"  appearance="outline" >
                
                <input formControlName="reportUrl" type="tel" matInput >
                <mat-error >Not a Valid Url</mat-error>
              </mat-form-field>
            </div>

          </mat-tab>
        </mat-tab-group>
        
          <!-- <mat-form-field  appearance="outline" >
            <mat-label>Subject Area</mat-label>
            <mat-select formControlName="subjectArea"  required>
              <mat-option *ngFor="let subjectArea of subjectAreas" value="subjectArea.id" >{{subjectArea.name}}</mat-option>
            </mat-select>
          </mat-form-field> -->
          
          
          <!-- <mat-form-field  appearance="outline" >
              <mat-label>Category</mat-label>
            
                  <mat-select formControlName="category">
                      <mat-option *ngFor="let category of categories" value="category.id">{{category.name}}</mat-option>
                  </mat-select>
            </mat-form-field>

            <mat-form-field  appearance="outline" >
              <mat-label>Sub Category</mat-label>
              <mat-select formControlName="subCategory">
                  <mat-option *ngFor="let subCategory of subCategories" value="subCategory.id">{{subCategory.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            -->
            <!-- <mat-form-field  appearance="outline" >
              <mat-label>Report</mat-label>
              <input type="tel" matInput >
            </mat-form-field> -->

        

      </form>
      <button  [disabled]="!formValid()" class="saveButton" (click)="save()" mat-raised-button color="primary"> Save <mat-icon class="actionIcon">chevron_right</mat-icon></button>
  </div>

</div>
