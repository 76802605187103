import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HeaderMenuComponent } from './shared/header-menu/header-menu.component';
import { ReportManagerComponent } from './shared/report-manager/report-manager.component';
import { ProfileComponent } from './Pages/profile/profile.component';
import { HomeComponent } from './Pages/home/home.component';
import { BrowserUtils } from '@azure/msal-browser';
import { MsalGuard } from '@azure/msal-angular';
import { ManageComponent } from './Pages/manage/manage.component';
import { AddReportMainComponent } from './Pages/add-report/add-report-main/add-report-main.component';
import { UrlViewerComponent } from './sharedV2/url-viewer/url-viewer.component';
import { SearchGptComponent } from './sharedV2/search-gpt/search-gpt.component';
import { DisclosureComponent } from './sharedV2/disclosure/disclosure.component';
import { GpthelpComponent } from './sharedV2/gpthelp/gpthelp.component';
import { TableBasicExample } from './shared/report-manager/deleteme/table-basic-example';

const routes: Routes = [
  { path: 'report-viewer/:groupid/:reportid/:reporttableid', component: HeaderMenuComponent, canActivate: [MsalGuard] },
  { path: 'report-viewer/:groupid/:reportid', component: HeaderMenuComponent, canActivate: [MsalGuard] },
  { path: 'url-viewer', component: UrlViewerComponent },
  { 
    path: 'search-gpt', component: SearchGptComponent,
    data: { breadcrumb: 'Search Gpt' }, 
    // children: [
    //   {
    //     path: 'disclosure',
    //     component: DisclosureComponent,
    //     data: {
    //       breadcrumb: 'Disclosure',
    //     }
    //   },
    // ],
  },
  { path: 'favorite-manager', component: TableBasicExample },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'manage',
    data: { breadcrumb: 'Manage' },
    component: ManageComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'add-report/:nodeType/:id',
    data: { breadcrumb: 'Add Report' },
    component: AddReportMainComponent,
    canActivate: [MsalGuard]
  }, {
    path: 'signin-oidc',
    component: HomeComponent
  },
  {
    path: 'disclosure',
    component: DisclosureComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'gpthelp',
    component: GpthelpComponent,
    canActivate: [MsalGuard]
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [MsalGuard]
  }
  // { path: '**', redirectTo: 'login' }
];


const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled', // Don't perform initial navigation in iframes
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
