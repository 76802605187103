import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ConfigResponse } from '../app.component';
import { BiGroupDto, BiReportDto } from '../models/bi';
import { Category } from '../models/category';
import { Country } from '../models/country';
import { DriveReport } from '../models/drive-report';
import { DriveReportResponse } from '../models/drive-report-response';
import { SubCategory } from '../models/sub-category';
import { SubjectArea } from '../models/subject-area';
import { environment } from 'src/environments/environment';
import { AppConfig } from '../models/app.config';
import { ConfigService } from './config.service';
import { AdGroup } from '../models/adGroup';
import { AddReportRequest } from '../models/Requests/AddReportRequest';
import { CreateAdGroupRequest } from '../models/Requests/CreateAdGroupRequest';
import { CreateCategoryRequest } from '../models/Requests/CreateCategoryRequest';
import { CreateSubCategoryRequest } from '../models/Requests/CreateSubCategoryRequest';
import { CreateSubCategoryTwoRequest } from '../models/Requests/CreateSubCategoryTwoRequest';
import { CreateSubjectAreaRequest } from '../models/Requests/CreateSubjectAreaRequest';
import { SeearchGptRequest, SeearchGptResponse } from '../models/Requests/SearchGptRequest';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})

/**
 * Service to make HTTP calls
 */
export class HttpService {
  baseUrl: string;
  config!: AppConfig;

  megamenuLoading = true;
  megaMenuSubjectAreas!: SubjectArea[];
  megaMenuSubjectAreasChange: Subject<SubjectArea[]> = new Subject<SubjectArea[]>;
  searchGptResponseChange: Subject<SeearchGptResponse> = new Subject<SeearchGptResponse>;
  searchGptLastSearch = '';
   
  constructor(private httpClient: HttpClient, private configService: ConfigService, private router: Router) {
    //debugger;
    this.config = this.configService.readConfig() as AppConfig;
    this.baseUrl = `${this.config.apiUrl}/api`;

    this.megaMenuSubjectAreasChange.subscribe((subjectAreas) => {
      this.megaMenuSubjectAreas = subjectAreas;
      this.megamenuLoading = false;
    })
  }


  /**
   * @returns embed configuration
   */
  getEmbedConfig(reportId: string, groupId: string): Observable<ConfigResponse> {
    //if(reportId)
    let requestUrl = `${this.baseUrl}/report/${reportId}/group/${groupId}`;
    return this.httpClient.get<ConfigResponse>(requestUrl);
  }

  CreateReportSimple(request: AddReportRequest): Observable<string> {
    const requestUrl = `${this.baseUrl}/report`;
    return this.httpClient.post<string>(requestUrl, request);
  }

  getBiGroups(): Observable<BiGroupDto[]> {
    let requestUrl = `${this.baseUrl}/report/groups`;
    return this.httpClient.get<BiGroupDto[]>(requestUrl);
  }

  getBiReports(groupId: string): Observable<BiReportDto[]> {
    let requestUrl = `${this.baseUrl}/report/bireport/${groupId}`;
    return this.httpClient.get<BiReportDto[]>(requestUrl);
  }

  getAllDriveReports(): Observable<DriveReportResponse[]> {
    let requestUrl = `${this.baseUrl}/report/drive-reports`;
    return this.httpClient.get<DriveReportResponse[]>(requestUrl);
  }

  getAdGroups(userId: string): Observable<AdGroup[]> {
    let requestUrl = `${this.baseUrl}/adgroup/${userId}`;
    return this.httpClient.get<AdGroup[]>(requestUrl);
  }

  getSubjectAreas(userId: string): Observable<SubjectArea[]> {
    let requestUrl = `${this.baseUrl}/subjectarea/${userId}`;
    return this.httpClient.get<SubjectArea[]>(requestUrl);
  }

  getMegaMenuSubjectAreas(adGroupId?: string): Observable<SubjectArea[]> {
    this.megamenuLoading = true;
    let requestUrl = `${this.baseUrl}/subjectarea/megamenu`;
    if(adGroupId) requestUrl = `${requestUrl}/${adGroupId}`
    const observable = this.httpClient.get<SubjectArea[]>(requestUrl);
    observable.subscribe((subjectAreas)=>{
      this.megaMenuSubjectAreasChange.next(subjectAreas);
    });
    
    return observable;
  }

  CreateAdGroup(request: CreateAdGroupRequest): Observable<string> {
    const requestUrl = `${this.baseUrl}/adgroup`;
    return this.httpClient.post<string>(requestUrl, request);
  }

  CreateSubjectArea(subjectArea: SubjectArea): Observable<string> {
    const requestUrl = `${this.baseUrl}/subjectarea`;
    return this.httpClient.post<string>(requestUrl, subjectArea);
  }

   SortSubjectAreas(request: string[]): Observable<boolean> {
    const requestUrl = `${this.baseUrl}/subjectarea/sort`;
    return this.httpClient.post<boolean>(requestUrl, request);
  }

     SortNodes(request: string[]): Observable<boolean> {
    const requestUrl = `${this.baseUrl}/subjectarea/sort`;
    return this.httpClient.post<boolean>(requestUrl, request);
  }

    CreateSubjectAreaNew(subjectArea: CreateSubjectAreaRequest): Observable<string> {
    const requestUrl = `${this.baseUrl}/subjectarea`;
    return this.httpClient.post<string>(requestUrl, subjectArea);
  }

  CreateCategory(category: Category): Observable<string> {
    const requestUrl = `${this.baseUrl}/category`;
    return this.httpClient.post<string>(requestUrl, category);
  }

  CreateCategoryNew(request: CreateCategoryRequest): Observable<string> {
    const requestUrl = `${this.baseUrl}/category`;
    return this.httpClient.post<string>(requestUrl, request);
  }

  CreateSubCategory(subCategory: SubCategory): Observable<string> {
    const requestUrl = `${this.baseUrl}/subcategory`;
    return this.httpClient.post<string>(requestUrl, subCategory);
  }

  CreateSubCategoryNew(request: CreateSubCategoryRequest): Observable<string> {
    const requestUrl = `${this.baseUrl}/subcategory`;
    return this.httpClient.post<string>(requestUrl, request);
  }

    CreateSubCategoryTwoNew(request: CreateSubCategoryTwoRequest): Observable<string> {
    const requestUrl = `${this.baseUrl}/subcategorytwo`;
    return this.httpClient.post<string>(requestUrl, request);
  }

  CreateReport(report: DriveReport): Observable<string> {
    const requestUrl = `${this.baseUrl}/report`;
    return this.httpClient.post<string>(requestUrl, report);
  }

 
  UpdateSubjectArea(subjectArea: SubjectArea): Observable<boolean> {
    const requestUrl = `${this.baseUrl}/subjectarea`;
    return this.httpClient.put<boolean>(requestUrl, subjectArea);
  }

  UpdateCategory(category: Category): Observable<boolean> {
    const requestUrl = `${this.baseUrl}/category`;
    return this.httpClient.put<boolean>(requestUrl, category);
  }

  UpdateSubCategory(subCategory: SubCategory): Observable<boolean> {
    const requestUrl = `${this.baseUrl}/subcategory`;
    return this.httpClient.put<boolean>(requestUrl, subCategory);
  }

  UpdateReport(report: DriveReport): Observable<boolean> {
    const requestUrl = `${this.baseUrl}/report`;
    return this.httpClient.put<boolean>(requestUrl, report);
  }

   DeleteAdGroup(adGroupId: string): Observable<boolean> {
    const requestUrl = `${this.baseUrl}/adgroup?id=${adGroupId}`;
    return this.httpClient.delete<boolean>(requestUrl);
  }

  DeleteSubjectArea(subjectAreaId: string): Observable<boolean> {
    const requestUrl = `${this.baseUrl}/subjectarea?subjectAreaId=${subjectAreaId}`;
    return this.httpClient.delete<boolean>(requestUrl);
  }


  DeleteCategory(categoryId: string): Observable<boolean> {
    const requestUrl = `${this.baseUrl}/category?categoryId=${categoryId}`;
    return this.httpClient.delete<boolean>(requestUrl);
  }

  DeleteSubCategory(subCategoryId: string): Observable<boolean> {
    const requestUrl = `${this.baseUrl}/subcategory?subCategoryId=${subCategoryId}`;
    return this.httpClient.delete<boolean>(requestUrl);
  }

  DeleteSubCategoryTwo(subCategoryTwoId: string): Observable<boolean> {
    const requestUrl = `${this.baseUrl}/subcategorytwo?subCategoryTwoId=${subCategoryTwoId}`;
    return this.httpClient.delete<boolean>(requestUrl);
  }

  DeleteReport(reportId: string): Observable<boolean> {
    const requestUrl = `${this.baseUrl}/report?reportId=${reportId}`;
    return this.httpClient.delete<boolean>(requestUrl);
  }

    getCountries(): Observable<Country[]> {
    let requestUrl = `${this.baseUrl}/metadata/country`;
    return this.httpClient.get<Country[]>(requestUrl);
  }

   searchGpt(request?: SeearchGptRequest): Observable<SeearchGptResponse> {

   

    this.searchGptLastSearch = request?.prompt as string;
    let body = new FormData();
    body.append('query', this.searchGptLastSearch);
    let requestUrl = `https://gallagheraidev.ajgco.com/drive/chat`;
    const observable = this.httpClient.post<SeearchGptResponse>(requestUrl, body);
    
    observable.subscribe(data => { 
      this.searchGptResponseChange.next(data);
    });
   
    return observable;
  }
}
