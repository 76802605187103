import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdGroup } from 'src/app/models/adGroup';
import { AppConfig } from 'src/app/models/app.config';
import { ConfigService } from 'src/app/services/config.service';
import { HttpService } from 'src/app/services/http.service';
import { MegamenuService } from 'src/app/services/megamenu.service';

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss']
})
export class ManageComponent implements OnInit {
  config!: AppConfig;
  adGroups!: AdGroup[];
  constructor(
    public httpService: HttpService, private megamenuService: MegamenuService, private _router:Router
  ) { }


  ngOnInit(): void {
    //navigates from tree view manage ascreen if user isnt admin. 
    // if(!this.megamenuService.isAdmin()) {
    //   debugger;
    //   this._router.navigate(['']).then(() => {
    //     window.location.reload();
    //     });
    // }
     
  }

}
