import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, startWith, map } from 'rxjs';
import { TreeHelperServiceService } from 'src/app/services/tree-helper-service.service';

// interface AutoCompleteModel {
//   name: string;
//   value: string;
// }

@Component({
  selector: 'app-multiselect-autocomplete-save',
  templateUrl: './multiselect-autocomplete-save.component.html',
  styleUrls: ['./multiselect-autocomplete-save.component.scss']
})
export class MultiselectAutocompleteSaveComponent implements OnChanges, OnInit{
  @Input() myControl = new FormControl('');
  @Input() labelName: string = '';
  @Input() required: boolean = false;

  @Input() disabled: boolean = false;

  @Output() latestText = new EventEmitter<string>();
  @Output() selectedOptionsString = new EventEmitter<string>();
  @Output() addNewEntry = new EventEmitter<string>();
  filteredOptions!: Observable<any[]>;

  private _options: any[] = [];
  @Input()
  set options(value: any[]){
    if(!value) return;

    this._options = value;

    if(this.treeHelper.treeLayout.adGroupIds) this.initialize(this.treeHelper.treeLayout.adGroupIds);

    

    if(this._options.some(x => x.isNew)) {
      const newOption = this._options.find(x => x.isNew);

    }

    var t = this.myControl.value;
    this.myControl.setValue(t);
    this.myControl.markAsDirty();

    //initialize sets values for this complex component in case they need to be preset
    // i.e. we came from tree screen to edit a report
    //this.initialize();
  }

  get options(): any [] {
    return this._options;
  }




  latestTextTemp = '';

  selectedOptions: any [] = [] ;

  constructor(private treeHelper: TreeHelperServiceService) {

  
 
    
  }


  ngOnChanges(changes: SimpleChanges) {
        
    
}

optionClicked(event: any, option: any){
  
  event.stopPropagation();

}

initialize(adGroups: string) {
  this.selectedOptions = [];
  if(adGroups && this.options.length > 0){
    const initializeIds = adGroups.split(","); //'4648ada3-1063-49e0-82c3-4b186d03fb67';
    initializeIds.forEach(id => {
      const initializeId = id.trim();
      const i = this.options.findIndex(value => value.id === initializeId );

      if(this.options[i])  this.selectedOptions.push(this.options[i]);
    })
  
    const stringOfSelectedOptionIds = this.getListOfStringFromSelectedOptions(this.selectedOptions)
    this.selectedOptionsString.emit(stringOfSelectedOptionIds);
  }
 
}

toggleSelection(option: any) {
  option.selected = !option.selected;
  if (option.selected) {
    this.selectedOptions.push(option);
  } else {
    const i = this.selectedOptions.findIndex(value => value.id === option.id );
    this.selectedOptions.splice(i, 1);
  }



  const stringOfSelectedOptionIds = this.getListOfStringFromSelectedOptions(this.selectedOptions)
  this.selectedOptionsString.emit(stringOfSelectedOptionIds);

}

getListOfStringFromSelectedOptions(selectedOptions: any[]) {

  const listOfSelectedOptionIds = selectedOptions.map((item) => {
    return item['id'];
  });

  const stringOfSelectedOptionIds = listOfSelectedOptionIds.join(',');

  return stringOfSelectedOptionIds;
}


  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );

  }

  remove(option: any) {
    const i = this.selectedOptions.findIndex(value => value.id === option.id );
    this.selectedOptions.splice(i, 1);
  }

  onChangeEvent($event: any){
    if($event){
      this.latestTextTemp = this.myControl.value as string;
      
      this.latestText.emit(this.latestTextTemp);
      
    }
  }

  onSelectionChange($event:  MatAutocompleteSelectedEvent){
    this.latestTextTemp = $event.option.viewValue;
    this.latestText.emit($event.option.viewValue);

  }

  isNewEntry(): boolean {
    
   return this.latestTextTemp.length > 2 && !this.options.some(e => e.name === this.latestTextTemp)
  }

  addNew() {
    if(this.myControl.value) {
      this.addNewEntry.emit(this.myControl.value);
    }
      
  }


  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  displayFn(value: any) {
    return this.options.find((option) => option.id === value)?.name;
    //return value ? value.name : undefined;
  }
}