<header>
    <div id="headertopmain" (mouseenter)="setHeaderHoveredMegamenu()">
        <div id="headertop" class="uni-pad">
            <div id="GallagherLogo">
                <a href=""><img style="width: 300px; margin-left: 70px;"  src="../../../assets/images/Drivelogo.svg" alt=""></a>
                <div id="Mobile3iconmain" style="display: none;">
                    <!-- <a  (click)="navigateToManageFavorites()" [routerLink]=""><img src="../../../assets/images/StarIcon.svg" alt="" id="Mobile3iconmainStar"></a> -->
                    <!-- <a href=""><img src="../../../assets/images/Serchicon.svg" alt="" id="Mobile3iconmainSearch"></a> -->
                    
                    <button mat-icon-button (click)="toggleMobileHeader()" id="MobileOptionsButton"><img src="../../../assets/images/MobileMenu.svg" alt=""
                        ></button>
                    
                    <!-- <button mat-icon-button id="MobileOptionsButton" [matMenuTriggerFor]="menu"><img src="../../../assets/images/MobileMenu.svg" alt=""
                        ></button>
                    <mat-menu #menu="matMenu" >
                        <button mat-menu-item [matMenuTriggerFor]="menuuu" (menuOpened)="openedFavMenu()"><i style="color: gold; " class="material-icons">star_rate</i>  Favorites<img style="margin-left: 5px;" src="../../../assets/images/Gallagherdropicon.svg" alt=""
                            id="rightarrowofmenu"></button>
                        <mat-menu #menuuu="matMenu" (closed)="closedFavMenu()">
                            <div style="display: flex; justify-content: left;" *ngFor="let report of userService.favoriteReports; index as i" (mouseenter)="setReportVisible(i)" (mouseleave)="setReportNotVisible(i)">
                                <button  color="accent" (click)="navigateToReportView(report)" style="cursor: pointer; color: #2b5a80; width: 200px;"  mat-menu-item>{{report.name}}</button> 
                                 <div matTooltip="Remove Report" matTooltipPosition="right" *ngIf="isVisible(report)" style="line-height: 2.7; margin-right: 5px;" (click)="deletefavoriteReport(report)"><b style="cursor: pointer; ">X</b></div>
                               
                                  </div>
                        
                                <div style="display: flex; justify-content: left;"  >
                                    <button  color="accent" (click)="navigateToManageFavorites()" style="cursor: pointer; color: #2b5a80; width: 200px;"  mat-menu-item><b>Manage Favorites</b></button> 
                                    </div>
                        </mat-menu> 
        

                        <a mat-menu-item  target="_blank"  href="https://teams.microsoft.com/l/team/19%3aeFpJ2xNGPlXo2vUHbAVurkuBnU0v0AAiPPCUnd4p5do1%40thread.tacv2/conversations?groupId=3734acb6-368c-47bb-9e6e-d086fd3ea8c6&tenantId=6cacd170-f897-4b19-ac58-46a23307b80a">Support</a>


                        <a mat-menu-item *ngIf="isAdmin()" [routerLink]="['manage']">Manage</a>

                      
      
                        <a mat-menu-item [routerLink]="['']">Home</a>
        
                        <a mat-menu-item *ngIf="!loginDisplay" (click)="login()">Login</a>
                        <a mat-menu-item *ngIf="loginDisplay" (click)="logout()">Logout</a>
                    </mat-menu>-->

                    <!-- <a href=""><img src="../../../assets/images/MobileMenu.svg" alt=""
                        id="Mobile3iconmainMobileMenu"></a> -->
                    <!-- <a href=""><img src="../../../assets/images/DriveCloseicon.svg" alt="" id="DriveCloseicon"></a> -->
                  </div>
            </div>
            

                <mat-form-field *ngIf="!windowsService.isMobileLayout || megamenuService.mobileHeaderVisible" id="searchBox" appearance="outline" style="margin-top: 3px; margin-bottom: -11px; margin-left: 5px;">
                    <mat-icon matPrefix>search</mat-icon>
                    <input type="text"
                        placeholder="Search on report title or keywords"
                        matInput
                        [formControl]="searchForm.controls.reportName"
                        [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete"  [displayWith]="displayFn(filteredDriveReportsSearchList)" >
                        <mat-option (click)="navigateToReportView(biReport)" style="    margin-bottom: 14px;" *ngFor="let biReport of filteredDriveReportsSearchList" [value]="biReport.id" >
                            <div *ngIf="!biReport.externalUrl"  style="display: flex; flex-direction: column;">
                                <div style="color: #00263E"><b>{{biReport.name}}</b></div>
                                <div>{{biReport.description}}</div>
                            </div>

                            <div *ngIf="biReport.externalUrl" (click)="goToLink(biReport)" style="display: flex; flex-direction: column;">
                                <div style="color: #00263E"><b>{{biReport.name}}</b></div>
                                <div>{{biReport.description}}</div>
                            </div>
                        

                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>  

            

                <!-- <mat-form-field class="input" color="accent" appearance="outline" style="width: 21vw;  margin-top: 3px; font-weight: 700;">
                    <mat-icon matPrefix>search</mat-icon>
                    <input type="text"
                        placeholder="Search on report title or keywords"
                        matInput
                        [formControl]="searchForm.controls.reportName"
                        (keydown.enter)="searchGpt()">
                
                </mat-form-field>   -->

                <!-- <div id="Gallaghersearchbar"><input type="search" id="" name=""
                        placeholder="Search on report title or keywordss">
                </div> -->
                <div id="Gallaghersearchbarempty" class="largerHeader">    <div class="spacer">
                    <span class="nametext" *ngIf="loginDisplay"><b>Hi, {{shrinkAccountName()}}</b></span>
                    <!-- <span  *ngIf="loginDisplay"><b>Hi, {{account?.name}}</b></span> -->

                    <button mat-button [matMenuTriggerFor]="menuu" (click)="$event.stopPropagation();"><i style="color: gold; " class="material-icons">star_rate</i>  Favorites<img style="margin-left: 5px;" src="../../../assets/images/Gallagherdropicon.svg" alt=""
                        id="rightarrowofmenu"></button>
                    <mat-menu #menuu="matMenu" xPosition="after">
                        <div style="display: flex; justify-content: left;" *ngFor="let report of userService.favoriteReports; index as i" (mouseenter)="setReportVisible(i)" (mouseleave)="setReportNotVisible(i)">
                            <button  color="accent" (click)="navigateToReportView(report)" style="cursor: pointer; color: #2b5a80; width: 200px;"  mat-menu-item>{{report.name}}</button> 
                            <!-- <i  (click)="deletefavoriteReport(report)" style="line-height: 2!important; cursor: pointer;" class="material-icons">cancel</i> -->
                            <div matTooltip="Remove Report" matTooltipPosition="right" *ngIf="isVisible(report)" style="line-height: 2.7; margin-right: 5px;" (click)="deletefavoriteReport(report)"><b style="cursor: pointer; ">X</b></div>
                        
                            <!-- <button matTooltip="Remove Report"  color="accent" (click)="deletefavoriteReport(report)" style="margin-right: 5px;"  mat-menu-item>X</button>  -->
                            </div>
                    
                            <div style="display: flex; justify-content: left;"  >
                                <button  color="accent" (click)="navigateToManageFavorites()" style="cursor: pointer; color: #2b5a80; width: 200px;"  mat-menu-item><b>Manage Favorites</b></button> 
                                </div>
                    </mat-menu>

                    <button mat-button [matMenuTriggerFor]="menuuu" (click)="$event.stopPropagation();">Recent Items<img style="margin-left: 5px;" src="../../../assets/images/Gallagherdropicon.svg" alt=""
                        id="rightarrowofmenu"></button>
                    <mat-menu #menuuu="matMenu" xPosition="after">
                        <div style="display: flex; justify-content: left;" *ngFor="let report of userService.visitReports; index as i" (mouseenter)="setReportVisible(i)" (mouseleave)="setReportNotVisible(i)">
                            <button  color="accent" (click)="navigateToReportView(report)" style="cursor: pointer; color: #2b5a80; width: 300px;"  mat-menu-item><b>{{i+1}}.</b> {{report.name}}</button> 
                            <!-- <i  (click)="deletefavoriteReport(report)" style="line-height: 2!important; cursor: pointer;" class="material-icons">cancel</i> -->
                            <div matTooltip="Remove Report" matTooltipPosition="right" *ngIf="isVisible(report)" style="line-height: 2.7; margin-right: 5px;" (click)="deletefavoriteReport(report)"><b style="cursor: pointer; ">X</b></div>
                        
                            <!-- <button matTooltip="Remove Report"  color="accent" (click)="deletefavoriteReport(report)" style="margin-right: 5px;"  mat-menu-item>X</button>  -->
                            </div>
                    
                            <div style="display: flex; justify-content: left;"  >
                                <button (click)="deleteReportVisits()" color="accent" style="cursor: pointer; color: #2b5a80; width: 200px;"  mat-menu-item><b>Clear Recents</b></button> 
                                </div>
                    </mat-menu>

                    <a mat-button  target="_blank"  href="https://teams.microsoft.com/l/team/19%3aeFpJ2xNGPlXo2vUHbAVurkuBnU0v0AAiPPCUnd4p5do1%40thread.tacv2/conversations?groupId=3734acb6-368c-47bb-9e6e-d086fd3ea8c6&tenantId=6cacd170-f897-4b19-ac58-46a23307b80a">Support</a>

                    <a mat-button *ngIf="isAdmin()" [routerLink]="['manage']">Manage</a>
        
                    <a mat-button [routerLink]="['']">Home</a>

                    <a mat-button *ngIf="!loginDisplay" (click)="login()">Login</a>
                    <a mat-button *ngIf="loginDisplay" (click)="logout()">Logout</a>
                </div>
            </div>
            <!-- <div id="GallagherQuickLinks"><a href=""><img src="Images/QuickLinksicon.svg" alt="">&nbsp;<span>Quick
                        Links</span>&nbsp;<img src="Images/QuickLinksdropdownicon.svg" alt=""></a>
            </div>
            <div id="GallagherChooseCountrymain">
                <div id="GallagherChooseCountry">
                    <a href=""><img src="Images/ChooseCountryicon.svg" alt="">&nbsp;<span>Choose
                            Country</span>&nbsp;<img src="Images/ChooseCountrydropdownicon.svg" alt=""></a>
                </div> 
            </div>-->
            <!-- <div class="toolbar-spacer"></div> -->
            <!-- <div class="spacer">
                <span  *ngIf="loginDisplay"><b>Hi, {{account?.name}}</b></span>

                <a mat-button *ngIf="isAdmin()" [routerLink]="['manage']">Manage</a>
      
                <a mat-button [routerLink]="['']">Home</a>
              
                <button mat-raised-button *ngIf="!loginDisplay" (click)="login()">Login</button>
                <button mat-raised-button *ngIf="loginDisplay" (click)="logout()">Logout</button>
            </div> -->
           
            <!-- <div id="Gallagheronline">
                <a href=""><img src="../../../assets/images/online.svg" alt=""></a>
            </div> -->
        </div>
    </div>
</header>

<app-drive-megamenu *ngIf="!windowsService.isMobileLayout"  style=" position: -webkit-sticky;
position: sticky;
top: 0; z-index: 1000;" [show]="loginDisplay"></app-drive-megamenu>

<app-drive-megamenu-mobile  *ngIf="windowsService.isMobileLayout && megamenuService.mobileHeaderVisible"></app-drive-megamenu-mobile>