import { Injectable } from '@angular/core';
import { AccountInfo } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root'
})
export class UserAccountService {
  account?: AccountInfo;
  constructor() { }
}
