import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ChatGpMessage } from 'src/app/models/chat-gp-message';
import { LinkGpButton } from 'src/app/models/link-button-gpmessage';
import { ConfigService } from 'src/app/services/config.service';
import { HttpService } from 'src/app/services/http.service';
import { UrlViewerServiceService } from '../url-viewer/url-viewer-service.service';
// import { ChatMessage, LinkButton } from '@microsoft/microsoft-graph-types';
// interface ChatMessage {
//   prompt: string;
//   isUser: boolean;
//   fillerMessage: boolean
// }
// interface LinkButton {
//   url: string;
//   text: string;
// }

@Component({
  selector: 'app-typewriter-card',
  templateUrl: './typewriter-card.component.html',
  styleUrls: ['./typewriter-card.component.scss']
})
export class TypewriterCardComponent {
  // @Input() message: ChatGpMessage = {} as ChatGpMessage;
  @Input() linkButtons: LinkGpButton[] = [];
  showLinkedButtons = false;

  _message: ChatGpMessage = {} as ChatGpMessage;;
  get message(): ChatGpMessage {
      return this._message;
  }
  @Input() set message(value: ChatGpMessage) {
      this._message = value;
      if(value.prompt.length > 0) {
        this.startTyping(value.prompt);
      }
  }

  typedText = '';

startTyping(messageToType: string) {
  let index = 0;
  let linkedButtonIndex = 0
  setInterval(()=> { 
    this.typedText = messageToType.substring(0, index);
    index = index + 1;
    if(index > messageToType.length) {
      this.showLinkedButtons = true;
    }
   }, 7);
  
}

  constructor(public httpService: HttpService, private configService: ConfigService, private _router:Router, private urlViewrService: UrlViewerServiceService, private route: ActivatedRoute) {}


  ngOnInit(): void {
    

    }

  test(url: string) {
    this.urlViewrService.setLatestUrl(url);
     this._router.navigate(['url-viewer']).then(() => {
     window.location.reload();
     });
 }
}
