<div id="addsuccessmain" class="uni-pad">
    <div id="addsuccess">
        <div class="cancel-button">
            <button mat-icon-button color="primary" aria-label="Example icon button with a menu icon">
                <mat-icon style="color: #00263E;">cancel</mat-icon>
              </button>
        </div>
        
        <h2>ERROR</h2>
        <span>Your request was not successful!</span>
    </div>
</div>