import { Component, EventEmitter, Output, VERSION } from '@angular/core';
import {HttpClientModule, HttpClient, HttpRequest, HttpResponse, HttpEventType} from '@angular/common/http';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent {
  @Output() base64StringUpdated: EventEmitter<string> = new EventEmitter();
  percentDone!: number;
  uploadSuccess!: boolean;

  constructor(
    private http: HttpClient,
    ) { }
    
  version = VERSION
  
  upload(event: any) {
    const selectedFiles = event.target.files;
    //pick from one of the 4 styles of file uploads below
    this.uploadAndProgress(selectedFiles);
  }

  
  uploadAndProgress(files: File[]){
    if(files) {
      const file = files[0];
      this.getBase64(file);
    }
  }
  
 getBase64(file: File){
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        this.base64StringUpdated.emit(reader.result as string);
      
    };
    reader.onerror = function (error) {
    };
  }

}
