import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { UrlViewerServiceService } from 'src/app/sharedV2/url-viewer/url-viewer-service.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  loginDisplay = false;

  constructor(private authService: MsalService, private msalBroadcastService: MsalBroadcastService, private _router: Router, private _sanitizer: DomSanitizer, private urlViewrService: UrlViewerServiceService, public userService: UserService) { }

  ngOnInit(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      })
  }

  setLoginDisplay() {
    let t = this.authService.instance.getAllAccounts();
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }


    navigateToUrl(url: string, reportId: string) {
      this.urlViewrService.setLatestUrl(url, reportId);
      this._router.navigate(['url-viewer']).then(() => {
        window.location.reload();
        });
    }

    routeToAjg() {
      window.open('https://www.ajg.com/us/gallagher-drive/', "_blank");
    
    }
    routeToOther() {
      window.open('https://forms.office.com/Pages/DesignPageV2.aspx?origin=NeoPortalPage&subpage=design&id=cNGsbJf4GUusWEaiMwe4Chl49RgbXlRLp86zeABWqwxUQ1NUMFk5RVE0MlJDMFA2MFkyV0RYQVBROS4u', "_blank");
    }

    routeToUrl(url: string) {
      
      window.open(url, "_blank");
    
    }
    
    goToLink(reportUrl: string, reportUrlAssociatedId: string){ 
      this.userService.AddUrlVisit(reportUrl, reportUrlAssociatedId).subscribe(x => {
        this.userService.addToVisitListLocal(x);
      });
      window.open(reportUrl, "_blank");
    }

    navigateToReportView(workspaceId: string, powerBiReportId: string, reportId: string) {
      // if(report.workSpaceId && report.powerBiReportId) { :groupid/:reportid/:reporttableid
        this._router.navigate(['report-viewer', workspaceId, powerBiReportId, reportId]).then(() => {
          window.location.reload();
          });
     
     }
}