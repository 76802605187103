<div id="report">
    <div class="report-div">
        <div id="manage-report-div" class="uni-pad">
            <div id="manage-report-div1">
                <p> <a style="cursor: pointer;" [routerLink]=""  (click)="goToManageReports()"> Manage Reports<img src="../../../assets/images/whiteVector3(Stroke).svg" alt=""
                            class="manage-report-img1"></a></p>
            </div>
            <div id="add-report-div">
                <p><a href=""> Add Report</a></p>
            </div>
        </div>
    </div>

    <div id="add-report-form1">
        <div id="add-report-form-main" class="uni-pad">
            <div id="add-report-form-div">
                

                <div style="width: 100%;">

                    <!-- Progress Bar div close -->
                    <form action="" class="report-form" >
                        <div id="add-report-form-div-heading">
                            <h2>Select Report</h2>
                            <form [formGroup]="reportForm" class="inputForm">
                                <div id="Form-Details">
                                    <div id="select-ad-group-div">
                                        <!-- <div id="select-ad-group-div-main" style="pointer-events: none;"> -->
                                        <div id="select-ad-group-div-main">
                                            <!-- <p><span style="color: #B70000;"> * </span> Select Group</p> -->
                                            <div>
                                                <app-multiselect-autocomplete-save (selectedOptionsString)="adGroupMultiSelectionChanged($event)" [required]="true" (addNewEntry)="addNewAdGroup($event)"  [myControl]="reportForm.controls.adGroup" labelName="AD Group" [options]="adGroups"></app-multiselect-autocomplete-save>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ad group div close -->

                                    <div id="subject-area-main">
                                        <div>
                                            <app-autocomplete-save [required]="true"  (addNewEntry)="addNewSubjectArea($event)"  [myControl]="reportForm.controls.subjectArea" labelName="Subject Area" [options]="subjectAreas"></app-autocomplete-save>
                                        </div>
                                    </div>
                                    <!-- subject area div close -->

                                    <div id="attachment-div">
                                        <div>
                                            <app-upload-images class="settingField" (base64StringUpdated)="updateImageBase64String($event)"></app-upload-images>
                                            <img  [src]="tempImageBase64" class="previewImageUpload">
                                        </div>
                                        <div></div>
                                    </div>
                                    <!-- attachment div close -->

                                
                                    <div style="display: flex; flex-direction: column;">
                                        <label><span style="color: red">*</span> Description</label>
                                        <mat-form-field class="input"  appearance="outline" >
                                        
                                        <input formControlName="subjectAreaDescription" type="tel" matInput >
                                        <mat-error >Description is required</mat-error>
                                        </mat-form-field>
                                    </div>
                                    
                                    <!-- Description div close -->
                                    <div id="select-category-main">
                                        <div> 
                                            <app-autocomplete-save [required]="true" (addNewEntry)="addNewCategory($event)"  [myControl]="reportForm.controls.category" labelName="Category" [options]="categories"></app-autocomplete-save>
                                        </div>
                                    </div>

                                    <!-- Categories div close -->
                                    <div id="select-sub-category-main">
                                        <div>
                                            <app-autocomplete-save  (addNewEntry)="addNewSubCategory($event)" [myControl]="reportForm.controls.subCategory" labelName="Sub Category" [options]="subCategories"></app-autocomplete-save>
                                        </div>
                                    </div>

                                    <!-- Sub Categories div close -->
                                    <div id="select-Sec-Sub-Categories-main">
                                        <div>
                                            <app-autocomplete-save  (addNewEntry)="addNewSubCategoryTwo($event)" [myControl]="reportForm.controls.subCategoryTwo" labelName="Second Sub Category" [options]="subCategoryTwos"></app-autocomplete-save>
                                        </div>
                                    </div>

                                    <!-- Sec.Sub-Categories div close -->
                                    <div id="choose-report">
                                        <div id="choose-report-heading">
                                            <h2>Choose Report</h2>
                                        </div>
                                        <div style="margin-top: 20px;"> 
                                            <div style="display: flex; flex-direction: column;">
                                            <label><span style="color: red">*</span> Report Name</label>
                                            <mat-form-field class="input"   appearance="outline" >
                                                <input formControlName="reportName" type="tel" matInput >
                                                <mat-error >Report name is required</mat-error>
                                            </mat-form-field>
                                            </div>
                                        </div>
                                        <mat-tab-group (selectedTabChange)="selectedTabValue($event)">
                                            <mat-tab label="Search from Drive"> 
                                            <div class="grid_container" style="margin-top: 30px;">
                                            
                                                <div style="display: flex; flex-direction: row; justify-content: left;">
                                                    <div style="display: flex; flex-direction: column; width: 49.5%;">
                                                        <label><span style="color: red">*</span> Work Space</label>
                                                        <mat-form-field class="input" appearance="outline" style=" margin-right: 30px; " >
                                                        <mat-icon matPrefix>cases</mat-icon>
                                                        <mat-select formControlName="workSpace"  >
                                                            <mat-option *ngFor="let biGroup of groupIdDropdownValues" [value]="biGroup.id" >{{biGroup.name}}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                    </div>
                                                
                                                    
                                                    <div style="display: flex; flex-direction: column; width: 49.5%;">
                                                        <label><span style="color: red">*</span> Report Id</label>
                                                        <mat-form-field class="input"appearance="outline" style=" "  >
                                                        <mat-icon matPrefix>cases</mat-icon>
                                                        <input type="text"
                                                            placeholder="Report"
                                                            matInput
                                                            [formControl]="reportForm.controls.reportId"
                                                            [matAutocomplete]="auto">
                                                        <mat-autocomplete #auto="matAutocomplete"  [displayWith]="displayFn(filteredReportIdDropdownValues)" >
                                                            <mat-option *ngFor="let biReport of filteredReportIdDropdownValues" [value]="biReport.id">{{biReport.name}}</mat-option>
                                                        </mat-autocomplete>
                                                        </mat-form-field>  
                                                    </div>
                                                    </div>
                                            
                                                
                                            
                                            </div>
                                            </mat-tab>
                                            <!-- <mat-tab label="File Upload"> 
                                            <div>
                                                <label><span style="color: red">*</span> Upload File</label>
                                                <mat-form-field class="input"  appearance="outline" >
                                                <app-file-uploader (base64StringUpdated)="updateReportUploadBase64String($event)"></app-file-uploader>
                                                <input type="tel" matInput >
                                                </mat-form-field>
                                            </div>
                                            </mat-tab> -->
                                            <mat-tab label="Web Link"> 
                                            <div style="display: flex; flex-direction: column; width: 99.5%;">
                                                <label><span style="color: red">*</span> Web Link</label>
                                                <mat-form-field class="input"  appearance="outline" >
                                                
                                                <input formControlName="reportUrl" type="tel" matInput >
                                                <mat-error >Not a Valid Url</mat-error>
                                                </mat-form-field>
                                            </div>

                                            <mat-checkbox formControlName="externalUrl">External URL (Open new tab)</mat-checkbox>
                                
                                            </mat-tab> 
                                        </mat-tab-group>
                                    </div>
                                    <!-- Choose Report div close -->

                                

                                </div>
                            </form>
                            <!-- form details div close -->
                        </div>
                    </form>
                    <!-- form close -->
                    <div id="cancel-save-buttons">
                            <button mat-stroked-button color="warn"  (click)="cancel()"  value="Submit" >Cancel</button>
                        <button mat-stroked-button color="primary" [disabled]="!formValid()" (click)="save()"  value="Submit" >Save</button>
                        
                    </div>

                </div>
                
                <!-- all cancel-save-buttons close -->
            </div>
            <!-- Select report div close -->
        </div>
        <!-- form div close -->
    </div>
    <!-- form sub div close -->
</div>