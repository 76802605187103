import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-response-modal',
  templateUrl: './response-modal.component.html',
  styleUrls: ['./response-modal.component.scss']
})
export class ResponseModalComponent {
  @Input() success: boolean = false;

message = 'Successfully Added Report';
ngOnInit(){
  
}


}
