import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
//import { FileUploadService } from '../../services/file-upload.service';

@Component({
  selector: 'app-upload-images',
  templateUrl: './upload-images.component.html',
  styleUrls: ['./upload-images.component.scss']
})
export class UploadImagesComponent implements OnInit {
  @Output() base64StringUpdated: EventEmitter<string> = new EventEmitter();

  @ViewChild('uploadFileBox') uploadFileBox!: ElementRef;

  selectedFiles?: FileList;
  selectedFileNames: string[] = [];

  progressInfos: any[] = [];
  message: string[] = [];

  previews: string[] = [];
  imageInfos?: Observable<any>;

  constructor() {}

  ngOnInit(): void {
   // this.imageInfos = this.uploadService.getFiles();
  }

  selectFiles(event: any): void {
    this.message = [];
    this.progressInfos = [];
    this.selectedFileNames = [];
    this.selectedFiles = event.target.files;

    this.previews = [];
    if (this.selectedFiles && this.selectedFiles[0]) {
      const numberOfFiles = this.selectedFiles.length;
      for (let i = 0; i < numberOfFiles; i++) {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          console.log(e.target.result);
          this.previews.push(e.target.result);
        };

        var base64 = this.getBase64(this.selectedFiles[i]);
        //var backToFile = this.base64toFile(base64 as string,'test.png');
        reader.readAsDataURL(this.selectedFiles[i]);

        this.selectedFileNames.push(this.selectedFiles[i].name);
      }
    }
  } 

  getBase64(file: File){
    var reader = new FileReader();
    reader.readAsDataURL(file);
    // reader.onload = function () {
    //   console.log(reader.result);
    //   this.base64String.emit(reader.result);
    //   //return reader.result;
      
    // };

    reader.onload = () => {
      console.log(reader.result);
        this.base64StringUpdated.emit(reader.result as string);
      //return reader.result;
      
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
      //return 'error';
      
    };
 }

 base64toFile(base64String: string, filename: string){

  const url = base64String;
  fetch(url)
  .then(res => res.blob())
  .then(blob => {
    const file = new File([blob], "File name",{ type: "image/png" });
    return file;
  })
 
}

  upload(idx: number, file: File): void {
    this.progressInfos[idx] = { value: 0, fileName: file.name };

    // if (file) {
    //   this.uploadService.upload(file).subscribe(
    //     (event: any) => {
    //       if (event.type === HttpEventType.UploadProgress) {
    //         this.progressInfos[idx].value = Math.round(
    //           (100 * event.loaded) / event.total
    //         );
    //       } else if (event instanceof HttpResponse) {
    //         const msg = 'Uploaded the file successfully: ' + file.name;
    //         this.message.push(msg);
    //         this.imageInfos = this.uploadService.getFiles();
    //       }
    //     },
    //     (err: any) => {
    //       this.progressInfos[idx].value = 0;
    //       const msg = 'Could not upload the file: ' + file.name;
    //       this.message.push(msg);
    //     }
    //   );
    // }
  }

  uploadFiles(): void {
    this.message = [];
    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        this.upload(i, this.selectedFiles[i]);
      }
    }
  }

  uploadFileBoxClick() {
    this.uploadFileBox.nativeElement.click();
  }
}
