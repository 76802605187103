import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { Country } from 'src/app/models/country';

/**
 * @title Chips Autocomplete
 */
@Component({
  selector: 'country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss'],
})
export class CountryselectComponent {
  //@Input() allCountries: string[] = [];
  @Input() myControl = new FormControl('');
  
  separatorKeysCodes: number[] = [ENTER, COMMA];
  countryCtrl = new FormControl('');
  filteredCountries: Observable<string[]>;
  @Output() selectedCountriesEmitted = new EventEmitter<string[]>();
  selectedCountries: string[] = [];
  @Input() allCountries: string[] = [];

  @ViewChild('countryInput')
  countryInput!: ElementRef<HTMLInputElement>;

  constructor() {
    this.filteredCountries = this.countryCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => (fruit ? this._filter(fruit) : this.allCountries.slice())),
    );
  }

  ngOnInit() {
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our country
    if (value) {
      this.selectedCountries.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.countryCtrl.setValue(null);

    this.selectedCountriesEmitted.emit(this.selectedCountries);
  }

  remove(fruit: string): void {
    const index = this.selectedCountries.indexOf(fruit);

    if (index >= 0) {
      this.selectedCountries.splice(index, 1);
    }

    this.selectedCountriesEmitted.emit(this.selectedCountries);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedCountries.push(event.option.viewValue);
    this.countryInput.nativeElement.value = '';
    this.countryCtrl.setValue(null);
    this.selectedCountriesEmitted.emit(this.selectedCountries);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allCountries.filter(fruit => fruit.toLowerCase().includes(filterValue));
  }
}
