import { Injectable } from '@angular/core';
import { ChatGpMessage } from '../models/chat-gp-message';
import { LinkGpButton } from '../models/link-button-gpmessage';

@Injectable({
  providedIn: 'root'
})
export class SearchGptServiceService {
  prevMessages: ChatGpMessage[] = [];
  prevLinkButtons: LinkGpButton[] = [];
  constructor() { }
}
