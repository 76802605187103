import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '../models/app.config';
import { ConfigService } from './config.service';
import { Observable, Subject } from 'rxjs';
import { SeearchGptRequest, SeearchGptResponse } from '../models/Requests/SearchGptRequest';
import { DriveReport } from '../models/drive-report';
import { AddUrlVisitRequest } from '../models/Requests/AddUrlVisitRequest';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userId!: string;

  baseUrl: string;
  config!: AppConfig;

   visitReportsResponseChange: Subject<DriveReport[]> = new Subject<DriveReport[]>;
   visitReports: DriveReport[] = [];

  favoriteReportsResponseChange: Subject<DriveReport[]> = new Subject<DriveReport[]>;
   favoriteReports: DriveReport[] = [];

  constructor(private httpClient: HttpClient, private configService: ConfigService, private router: Router) {
    //debugger;
    this.config = this.configService.readConfig() as AppConfig;
    this.baseUrl = `${this.config.apiUrl}/api`;

  }

  removeFromFavoritesListLocal(reportTableId: string) {
     const filtered = this.favoriteReports.filter(function( obj ) {
        return obj.id !== reportTableId;
    });

    this.favoriteReports = filtered;
  }

  removeFromVisitListLocal() {
    this.visitReports = [];
  }

  addToVisitListLocal(visReport: DriveReport) {
    debugger;
     this.visitReports.unshift(visReport);
  }

  addToFavoritesListLocal(favReport: DriveReport) {
   // this.favoriteReports.push(favReport);
    this.favoriteReports.unshift(favReport);
 }

  DeleteReportFavorites(reportId: string): Observable<boolean> {
    let requestUrl = `${this.baseUrl}/user/${this.userId}/report/${reportId}/favorite`;
    return this.httpClient.delete<boolean>(requestUrl);
  }

  AddReportFavorites(reportId: string): Observable<DriveReport> {
    let requestUrl = `${this.baseUrl}/user/${this.userId}/report/${reportId}/favorite`;
    return this.httpClient.post<DriveReport>(requestUrl,{});
  }

   OrderReportFavorites(reportIds: string[]): Observable<DriveReport> {
    let requestUrl = `${this.baseUrl}/user/${this.userId}/report/order`;
    return this.httpClient.post<DriveReport>(requestUrl,reportIds);
  }

  getFavoriteReportsForUser(): Observable<DriveReport[]> {
    let requestUrl = `${this.baseUrl}/user/${this.userId}/favorite`;
    const observable = this.httpClient.get<DriveReport[]>(requestUrl);
    observable.subscribe(data => { 
      this.favoriteReportsResponseChange.next(data);
      this.favoriteReports =  data;//data.reverse();
    });
   
    return observable;
  }

  getReportVisitsForUser(): Observable<DriveReport[]> {
    let requestUrl = `${this.baseUrl}/user/${this.userId}/visit`;
    const observable = this.httpClient.get<DriveReport[]>(requestUrl);
    observable.subscribe(data => { 
      this.visitReportsResponseChange.next(data);
      this.visitReports =  data;//data.reverse();
    });
   
    return observable;
  }

  AddReportVisit(reportId: string): Observable<DriveReport> {
    let requestUrl = `${this.baseUrl}/user/${this.userId}/report/${reportId}/visit`;
    return this.httpClient.post<DriveReport>(requestUrl,{});
  }

  AddUrlVisit(url: string, reportId: string): Observable<DriveReport> {
    const request: AddUrlVisitRequest = {url: url} as AddUrlVisitRequest;
    let requestUrl = `${this.baseUrl}/user/${this.userId}/report/${reportId}/urlview/visit`;
    debugger;
    return this.httpClient.post<DriveReport>(requestUrl,request);
  }

  DeleteReportVisits(): Observable<boolean> {
    let requestUrl = `${this.baseUrl}/user/${this.userId}/visit`;
    return this.httpClient.delete<boolean>(requestUrl);
  }


}
