import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadCrumb } from 'src/app/models/breadcrumb';
import { SubjectArea } from 'src/app/models/subject-area';
import { HttpService } from 'src/app/services/http.service';
import { TreeHelperServiceService } from 'src/app/services/tree-helper-service.service';
import { UrlViewerServiceService } from 'src/app/sharedV2/url-viewer/url-viewer-service.service';

@Component({
  selector: 'app-breadcrumb-viewer',
  templateUrl: './breadcrumb-viewer.component.html',
  styleUrls: ['./breadcrumb-viewer.component.scss']
})
export class BreadcrumbViewerComponent {

  constructor(private urlViewrService: UrlViewerServiceService, public httpService: HttpService,private treeHelper: TreeHelperServiceService, private route: ActivatedRoute, private router: Router) {
  }

  breadCrumb?: BreadCrumb;


  ngOnInit(): void {

      const route = this.router.url;
      const reportId = route.split('/')[4];

      if(!reportId){
        const t = this.urlViewrService.getLatestUrl();
      }

    this.treeHelper.getBreadCrumbForReportId(reportId).subscribe(x => {
      this.breadCrumb = x;
    })
    
  }

  
  

}
