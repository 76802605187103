import { Component } from '@angular/core';

@Component({
  selector: 'app-gpthelp',
  templateUrl: './gpthelp.component.html',
  styleUrls: ['./gpthelp.component.scss']
})
export class GpthelpComponent {

}
