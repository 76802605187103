import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { IHttpPostMessageResponse } from 'http-post-message';
import { IReportEmbedConfiguration, models, Page, Report, service, VisualDescriptor } from 'powerbi-client';
import { PowerBIReportEmbedComponent } from 'powerbi-client-angular';
import 'powerbi-report-authoring';
import { errorClass, errorElement, hidden, position, successClass, successElement, tokenId, token, embedUrl } from '../constants';
import { HttpService } from '../services/http.service';
import { TokenType } from '@angular/compiler';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../services/user.service';
import { DriveReport } from '../models/drive-report';
import { WindowServiceService } from '../services/window-service.service';

// Handles the embed config response for embedding
export interface ConfigResponse {
  embedUrl: string;
  embedToken: string;
  reportId: string;
  isUserAuth:boolean;
  // Id: string;
  // EmbedUrl: string;
  // EmbedToken: {
  //   Token: string;
  // };
}

@Component({
  selector: 'app-report-viewer',
  templateUrl: './report-viewer.component.html',
  styleUrls: ['./report-viewer.component.scss']
})
export class ReportViewerComponent {
  // Wrapper object to access report properties
  @ViewChild(PowerBIReportEmbedComponent) reportObj!: PowerBIReportEmbedComponent;

  // Div object to show status of the demo app
  @ViewChild('status') private statusRef!: ElementRef<HTMLDivElement>;

  // Embed Report button element of the demo app
  @ViewChild('embedReportBtn') private embedBtnRef!: ElementRef<HTMLButtonElement>;

  private _currentReportId: string = '';
  private _currentGroupId: string = '';
  private _reportTableId: string = '';

  reportTypeNotVisible = false;

  @Input()
  set currentReportId(value: string) {
  }

  get currentReportId(): string {
    return this._currentReportId;
  }

  
  

  ngOnInit(){
    
    this.route.paramMap.subscribe(params => {
      this._currentGroupId = params.get('groupid') as string;
      this._currentReportId  = params.get('reportid') as string;
      this._reportTableId =  params.get('reporttableid') as string; 
     

      if(this._currentGroupId == 'null' || this._currentReportId  == 'null' ) 
        this.reportTypeNotVisible = true

      this.embedReport(this._currentReportId, this._currentGroupId).then(value => {}) ;
    });
  }

  ngAfterViewInit() {
 
  }

  // Track Report embedding status
  isEmbedded = false;

  // Overall status message of embedding
  displayMessage = '';

  // CSS Class to be passed to the wrapper
  // Hide the report container initially
  reportClass = 'report-container hidden';

  // Flag which specify the type of embedding
  phasedEmbeddingFlag = false;

  // Pass the basic embed configurations to the wrapper to bootstrap the report on first load
  // Values for properties like embedUrl, accessToken and settings will be set on click of button
  reportConfig: IReportEmbedConfiguration = {
    type: 'report', //
    embedUrl: undefined,
    tokenType:models.TokenType.Aad, //models.TokenType.Embed, //models.TokenType.Aad
    accessToken: undefined,
    settings: {
      bars: {
         actionBar: {
            visible: true
         }
      }
   },
  };

  /**
   * Map of event handlers to be applied to the embedded report
   */
  // Update event handlers for the report by redefining the map using this.eventHandlersMap
  // Set event handler to null if event needs to be removed
  // Event Handlers
  eventHandlersMap = new Map<string, (event?: service.ICustomEvent<any>) => void>([
    ['loaded', () => console.log('Report has loaded')],
    [
      'rendered',
      () => {
        console.log('Report has rendered');

        // Set displayMessage to empty when rendered for the first time
        if (!this.isEmbedded) {
          //this.displayMessage = 'Use the buttons above to interact with the report using Power BI Client APIs.';
        }

        // Update embed status
        this.isEmbedded = true;
      },
    ],
    [
      'error',
      (event?: service.ICustomEvent<any>) => {
        if (event) {
          console.error(event.detail);
        }
      },
    ],
    ['visualClicked', () => console.log('visual clicked')],
    ['pageChanged', (event) => console.log(event)],
  ]);

  constructor(public httpService: HttpService, private element: ElementRef<HTMLDivElement>, private route: ActivatedRoute, private userService: UserService, public windowsService: WindowServiceService) {
    setTimeout(() => {
        this.userService.AddReportVisit(this._reportTableId).subscribe(x => {
          debugger;
          this.userService.addToVisitListLocal(x);
        });
    }, 2000);
    
  }

  /**
   * Embeds report
   *
   * @returns Promise<void>
   */
  async embedReport(reportId: string, groupId: string): Promise<void> {
    let reportConfigResponse: ConfigResponse | undefined;

    // Get the embed config from the service and set the reportConfigResponse
    try {
      reportConfigResponse = await this.httpService.getEmbedConfig(reportId, groupId).toPromise();
    } catch (error: any) {
      this.displayMessage = `Failed to fetch config for report. Status: ${error.statusText} Status Code: ${error.status}`;
      console.error(this.displayMessage);
      this.displayMessage = 'You are not authorized to view this report.'
      // Prepare status message for Embed failure
      await this.prepareDisplayMessageForEmbed(errorElement, errorClass);
     
      
      return;
    }

    if(!reportConfigResponse)
      return;
    // Update the reportConfig to embed the PowerBI report
   
    this.reportConfig = {
      ...this.reportConfig,
      id: reportConfigResponse.reportId, //Id of the report.. right??
      embedUrl: reportConfigResponse.embedUrl,
      accessToken: reportConfigResponse.embedToken, //jwt from ad
      tokenType: reportConfigResponse.isUserAuth ? models.TokenType.Aad : models.TokenType.Embed
    };
    // this.reportConfig = {
    //   ...this.reportConfig,
    //   id: tokenId,
    //   embedUrl: embedUrl,
    //   accessToken: token,
    // };

    // Get the reference of the report-container div
    const reportDiv = this.element.nativeElement.querySelector('.report-container');
    if (reportDiv) {
      // When Embed report is clicked, show the report container div
      reportDiv.classList.remove(hidden);
    }

    // Get the reference of the display-message div
    const displayMessage = this.element.nativeElement.querySelector('.display-message');
    if (displayMessage) {
      // When Embed report is clicked, change the position of the display-message
      displayMessage.classList.remove(position);
    }

    this.reportObj.getReport().reload();

    // // Prepare status message for Embed success
    // await this.prepareDisplayMessageForEmbed(successElement, successClass);

    // // Update the display message
    // this.displayMessage = 'Access token is successfully set. Loading Power BI report.';
  }

  /**
   * Handle Report embedding flow
   * @param img Image to show with the display message
   * @param type Type of the message
   *
   * @returns Promise<void>
   */
  async prepareDisplayMessageForEmbed(img: HTMLImageElement, type: string): Promise<void> {
    // Remove the Embed Report button from UI
    this.embedBtnRef.nativeElement.remove();

    // Prepend the Image element to the display message
    this.statusRef.nativeElement.prepend(img);

    // Set type of the message
    this.statusRef.nativeElement.classList.add(type);
  }

  /**
   * Delete visual
   *
   * @returns Promise<void>
   */
  async deleteVisual(): Promise<void> {
    // Get report from the wrapper component
    const report: Report = this.reportObj.getReport();

    if (!report) {
      // Prepare status message for Error
      this.prepareStatusMessage(errorElement, errorClass);
      this.displayMessage = 'Report not available.';
      return;
    }

    // Get all the pages of the report
    const pages: Page[] = await report.getPages();

    // Check if all the pages of the report deleted
    if (pages.length === 0) {
      // Prepare status message for Error
      this.prepareStatusMessage(errorElement, errorClass);
      this.displayMessage = 'No pages found.';
      return;
    }

    // Get active page of the report
    const activePage: Page | undefined = pages.find((page) => page.isActive);

    if (activePage) {
      // Get all visuals in the active page of the report
      const visuals: VisualDescriptor[] = await activePage.getVisuals();

      if (visuals.length === 0) {
        // Prepare status message for Error
        this.prepareStatusMessage(errorElement, errorClass);
        this.displayMessage = 'No visuals found.';
        return;
      }

      // Get first visible visual
      const visual: VisualDescriptor | undefined = visuals.find((v) => v.layout.displayState?.mode === models.VisualContainerDisplayMode.Visible);

      // No visible visual found
      if (!visual) {
        // Prepare status message for Error
        this.prepareStatusMessage(errorElement, errorClass);
        this.displayMessage = 'No visible visual available to delete.';
        return;
      }

      try {
        
        const response = await activePage.deleteVisual(visual.name);

        // Prepare status message for success
        this.prepareStatusMessage(successElement, successClass);
        this.displayMessage = `${visual.type} visual was deleted.`;

        return response;
      } catch (error) {
        console.error(error);
      }
    }
  }

  /**
   * Hide Filter Pane
   *
   * @returns Promise<IHttpPostMessageResponse<void> | undefined>
   */
  async hideFilterPane(): Promise<IHttpPostMessageResponse<void> | undefined> {
    // Get report from the wrapper component
    const report: Report = this.reportObj.getReport();

    if (!report) {
      // Prepare status message for Error
      this.prepareStatusMessage(errorElement, errorClass);
      this.displayMessage = 'Report not available.';
      return;
    }

    // New settings to hide filter pane
    const settings = {
      panes: {
        filters: {
          expanded: false,
          visible: false,
        },
      },
    };

    try {
      const response = await report.updateSettings(settings);

      // Prepare status message for success
      this.prepareStatusMessage(successElement, successClass);
      this.displayMessage = 'Filter pane is hidden.';

      return response;
    } catch (error) {
      console.error(error);
      return;
    }
  }

  /**
   * Set data selected event
   *
   * @returns void
   */
  setDataSelectedEvent(): void {
    // Adding dataSelected event in eventHandlersMap
    this.eventHandlersMap = new Map<string, (event?: service.ICustomEvent<any>) => void>([
      ...this.eventHandlersMap,
      ['dataSelected', (event) => console.log(event)],
    ]);

    // Prepare status message for success
    this.prepareStatusMessage(successElement, successClass);
    this.displayMessage = 'Data Selected event set successfully. Select data to see event in console.';
  }

  /**
   * Prepare status message while using JS SDK APIs
   * @param img Image to show with the display message
   * @param type Type of the message
   *
   * @returns void
   */
  prepareStatusMessage(img: HTMLImageElement, type: string) {
    // Prepend Image to the display message
    this.statusRef.nativeElement.prepend(img);

    // Add class to the display message
    this.statusRef.nativeElement.classList.add(type);
  }

  favoriteReport() {
    this.userService.AddReportFavorites(this._reportTableId).subscribe(x => {
      this.userService.addToFavoritesListLocal(x);
    })
  }

  isFavorited() {
    const favReports = this.userService.favoriteReports;

    let found = false;

    favReports.forEach(x => {
        if (x.id === this._reportTableId) {
           found = true
        }
    });

    return found;
  }


  deletefavoriteReport() {
    this.userService.DeleteReportFavorites(this._reportTableId).subscribe(x => {
      this.userService.removeFromFavoritesListLocal(this._reportTableId);
    })
  }
}

