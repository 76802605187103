
<mat-toolbar class="loginToolBar">
  <button mat-button color="basic">
    Contact Us
  </button>
  <button mat-button color="basic">
    Log In
  </button> 
</mat-toolbar>
<mat-toolbar class="logoToolBar">
 
    <mat-toolbar-row>
        <img class="logo" src="../../../assets/images/gallagher.png" alt="">
        <mat-divider [vertical]="true"></mat-divider>
    <span class="logoText">Drive</span>
    <span class="spacer"></span>
<!-- 
    <form class="example-form">
        <mat-form-field  appearance="outline" >
          <mat-label>Search</mat-label>
          <mat-icon matPrefix>search</mat-icon>
          <input type="tel" matInput >
          
        </mat-form-field>
        

        <mat-form-field class="headerInput" appearance="outline" >
            <mat-label>Favorite</mat-label>
            <mat-icon matPrefix>cases</mat-icon>
            <mat-select>
             
            </mat-select>
          </mat-form-field>
    
          <mat-form-field appearance="outline" >
            <mat-label>Country</mat-label>
            <mat-icon matPrefix>flag</mat-icon>
            <mat-select>
             
            </mat-select>
          </mat-form-field>
      </form> -->
<!--     
    <button mat-button color="basic">
        Contact Us
      </button>
    <button mat-button color="basic">
        Log In
      </button> -->
     
      
      <mat-divider></mat-divider>
    </mat-toolbar-row>

    
</mat-toolbar>
<!-- 
<app-side-nav></app-side-nav> -->

<!-- KEEP! -->
<router-outlet></router-outlet>
<!-- <app-header-menu></app-header-menu> 
<app-report-manager></app-report-manager> -->


