import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-warning-modal',
	templateUrl: './warning-modal.component.html',
	styleUrls: ['./warning-modal.component.scss'],
})
export class WarningModalComponent implements OnInit {
	@Input()
	public promptMessage!: string;

	@Input()
	public yesBtnLabel!: string;

	@Input()
	public noBtnLabel!: string;

	@Input()
	public buttonAlignment: string = 'center';

	@Input()
	public isCancelButton: boolean = false;

	@Output()
	public yesClicked: EventEmitter<void> = new EventEmitter();

	@Output()
	public noClicked: EventEmitter<void> = new EventEmitter();

	@Output()
	public headerIconClose: EventEmitter<void> = new EventEmitter();

	constructor() {}

	public ngOnInit(): void {}

	public onYes(): void {
		this.yesClicked.emit();
	}

	public onNo(): void {
		this.noClicked.emit();
	}

	public onCloseDialog(): void {
		this.headerIconClose.emit();
	}
}
