import { Component, EventEmitter, Input, Output } from '@angular/core';
import {CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray} from '@angular/cdk/drag-drop';
import {NgFor} from '@angular/common';
import { SubjectArea } from 'src/app/models/subject-area';
import { HttpService } from 'src/app/services/http.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-subjectarea-drag-sort',
  templateUrl: './subjectarea-drag-sort.component.html',
  styleUrls: ['./subjectarea-drag-sort.component.scss']
})
export class SubjectareaDragSortComponent {

  constructor(public httpService: HttpService, private _router:Router) {

  }
  _subjectAreas!: SubjectArea[];
  get subjectAreas(): SubjectArea[] {
      return this._subjectAreas;
  }

  @Input() set subjectAreas(value: SubjectArea[]) {
      const cleanValues = value.filter(x => x.id !== 'add');

      this._subjectAreas = cleanValues;
  }



  @Output() subjectAreaOrdersUpdated = new EventEmitter<SubjectArea[]>();

  movies = [
    'Episode I - The Phantom Menace',
    'Episode II - Attack of the Clones',
    'Episode III - Revenge of the Sith',
    'Episode IV - A New Hope',
    'Episode V - The Empire Strikes Back',
    'Episode VI - Return of the Jedi',
    'Episode VII - The Force Awakens',
    'Episode VIII - The Last Jedi',
    'Episode IX – The Rise of Skywalker',
  ];

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.subjectAreas, event.previousIndex, event.currentIndex);
  }

  save(){
    const subjectAreaIds = this.subjectAreas.map(x => x.id);
    const filteredSubjectAreas = subjectAreaIds.filter(x => this.isValidGUID(x));
    this.httpService.SortSubjectAreas(filteredSubjectAreas).subscribe(x => {
      this.subjectAreaOrdersUpdated.emit(this.subjectAreas);
      // this._router.navigate(['manage']).then(() => {
      //   window.location.reload();
      //   });
    });

    
  };



isValidGUID(str: string) {
  // Regex to check valid
  // GUID 
  let regex = new RegExp(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/);

  // if str
  // is empty return false
  if (str == null) {
      return "false";
  }

  // Return true if the str
  // matched the ReGex
  if (regex.test(str) == true) {
      return "true";
  }
  else {
      return "false";
  }
}

}
