
<div class="main">
    <!-- <h3> Disclosure: </h3> -->
    <h4>
        <b>

            Disclosure Agreement:
        </b>
    </h4>

    <div>

    
    Disclaimer: You must not enter any Gallagher information classified as ‘Confidential’ or ‘Restricted’ into any unapproved AI System. To avoid any doubt, all publicly accessible AI systems are unapproved AI Systems and you should not be uploading any Gallagher confidential or restricted information. You must not use such AI Systems to conduct any Gallagher business. We actively monitor uploads of Gallagher information from the Gallagher network to publicly accessible AI tools and reserve the right to take action and/or block usage. You may use publicly accessible AI systems to help you become more productive, but you must abide by the above rules. Examples of permissible use include generating an outline of a long document you provide or to provide a summary of a particular topic. If you are using a Gallagher approved AI System, the output from that system is the Company’s proprietary information and must not be shared outside of Gallagher. (01/08/2024)
    </div>
      

</div>