


<div class="menu-edit-header">
  <h1>Menu Editor</h1>

  <div class="headerbuttons">
    <mat-form-field class="adgroup-select" appearance="outline">
      <mat-select #matRef placeholder="Select Ad Group" (selectionChange)="adGroupSelected($event.value)" >
        <mat-option ></mat-option>
        <mat-option *ngFor="let adGroup of adGroups" [value]="adGroup">{{adGroup.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-icon-button (click)="navigateToAddReport()"><mat-icon class="actionIcon">add</mat-icon></button>
    <button *ngIf="selectedAdGroup?.name" mat-icon-button color="primary" (click)="deleteAdGroup()"><mat-icon class="delete">delete</mat-icon></button>
    <button class="sortButton" mat-icon-button color="primary" *ngIf="!isOrderingSubjectAreas && subjectAreas.length > 2" (click)="sortNode()"  value="Submit" ><mat-icon>sort</mat-icon></button>
  </div>
  
  
</div>


<div  class="main-card">
  <!-- <app-subjectarea-drag-sort *ngIf="isOrderingSubjectAreas" (subjectAreaOrdersUpdated)="subjectAreaOrdersUpdated($event)" [subjectAreas]="subjectAreas"></app-subjectarea-drag-sort> -->
  <app-node-drag-sort *ngIf="isOrderingSubjectAreas" (nodeOrdersUpdated)="subjectAreaOrdersUpdated($event)" [nodes]="sortableNodes"></app-node-drag-sort>
</div>

<div *ngIf="!this.adGroups" class="adButtonBox">
  <button mat-button (click)="navigateToAddReport()"><mat-icon class="actionIcon">add</mat-icon> Add AD Group
  </button>
</div>



<mat-card *ngIf="!isOrderingSubjectAreas" class="main-card">
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree" #treeSelector>
    <!-- This is the tree node template for leaf nodes -->
    <!-- There is inline padding applied to this node using styles.
      This padding value depends on the mat-icon-button width. -->
    <mat-tree-node [ngClass]="{
      'subject-area-node': isSubjectAreaNode(node), 
      'category-node': isCategoryNode(node),
      'subcategory-node': isSubCategoryNode(node),
      'second-subcategory-node': isSubCategoryTwoNode(node),
      'report-node': isDriveReportNode(node),
      'add-node': isAddNewSubjectAreaNode(node)}"  *matTreeNodeDef="let node" matTreeNodeToggle>
      <div [ngClass]="{
        'subject-area-node': isSubjectAreaNode(node), 
        'category-node': isCategoryNode(node),
        'subcategory-node': isSubCategoryNode(node),
        'second-subcategory-node': isSubCategoryTwoNode(node),
        'report-node': isDriveReportNode(node),
        'add-node': isAddNewSubjectAreaNode(node)}" class="mat-tree-node"    >

        
          <div class="node-info" >
            <img style="max-height: 40px;"  [src]="node.image" class="preview">
            <span *ngIf="isSubjectAreaNode(node)"><b>Subject:</b> {{node.name}}</span> 
            <span class="node-info" *ngIf="isSubjectAreaNode(node)"><b>Description:</b> {{node.description ?? '---'}}</span> 
            <!-- <img style="max-height: 40px;"  [src]="node.safeResourceUrl" class="preview"> -->
            


            <span *ngIf="isCategoryNode(node)"><b>Category: </b>{{node.name}}</span> 
            <!-- <span class="node-info" *ngIf="isCategoryNode(node)"><b>Description:</b> {{node.description ?? '---'}}</span>  -->
    
            <span *ngIf="isSubCategoryNode(node)"><b>Sub Category:</b> {{node.name}}</span> 
            <!-- <span class="node-info" *ngIf="isSubCategoryNode(node)"><b>Description:</b> {{node.description ?? '---'}}</span>  -->
    
            <span *ngIf="isSubCategoryTwoNode(node)"><b>Second Sub Category:</b> {{node.name}}</span> 
            <!-- <span class="node-info" *ngIf="isSubCategoryTwoNode(node)"><b>Description:</b> {{node.description ?? '---'}}</span>  -->
    
            <span *ngIf="isDriveReportNode(node)"><b>Report:</b> {{node.name}}</span> 
            <!-- <span  class="node-info" *ngIf="isDriveReportNode(node)"><b>Description:</b> {{node.description ?? '---'}}</span>  -->

            <span *ngIf="isAddNewSubjectAreaNode(node)">Add New Subject Area</span>

          </div>
          
          
          <!-- <button class="addReportButton"  mat-raised-button color="primary"><mat-icon class="actionIcon">add</mat-icon> Add Report</button> -->
          <div>
            <span *ngIf="!isAddNewSubjectAreaNode(node)"><b>Modified Date:</b> {{node.updatedDate ?? node.createdDate | date}}</span>
            <button class="sortButton" *ngIf="isSortable(node)" mat-icon-button color="primary" (click)="sortNode(node)"   value="Submit" ><mat-icon>sort</mat-icon></button>
            <button class="sortButton"  *ngIf="isEditable(node)" mat-icon-button color="primary" (click)="openEditNodeDialog(node)"  ><mat-icon>edit</mat-icon></button>
         
            <button  class="addReportButton" (click)="addReport(node)" mat-icon-button color="primary" color="primary"><mat-icon [ngClass]="{'add-node-icon': isAddNewSubjectAreaNode(node)}"  class="actionIcon">add</mat-icon></button>
            <button *ngIf="!isAddNewSubjectAreaNode(node)" mat-icon-button color="primary" (click)="deleteNode(node)"><mat-icon class="delete">delete</mat-icon></button>
          </div>

      

       
      </div>
     
     
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <div [ngClass]="{
          'subject-area-node': isSubjectAreaNode(node), 
          'category-node': isCategoryNode(node),
          'subcategory-node': isSubCategoryNode(node),
          'second-subcategory-node': isSubCategoryTwoNode(node),
          'report-node': isDriveReportNode(node)}" class="mat-tree-node" >
         

            <div>
              <button mat-icon-button matTreeNodeToggle
                      [attr.aria-label]="'Toggle ' + node.name">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>
              <img style="max-height: 40px;"  [src]="node.image" class="preview">
              <span *ngIf="isSubjectAreaNode(node)"><b>Subject:</b> {{node.name}}</span> 
              <!-- <img style="max-height: 40px;"  [src]="node.safeResourceUrl" class="preview"> -->
              <span class="node-info" *ngIf="isSubjectAreaNode(node)"><b>Description:</b> {{node.description ?? '---'}}</span> 
      
              <span *ngIf="isCategoryNode(node)"><b>Category: </b>{{node.name}}</span> 
              <!-- <span class="node-info" *ngIf="isCategoryNode(node)"><b>Description:</b> {{node.description ?? '---'}}</span>  -->
      
              <span *ngIf="isSubCategoryNode(node)"><b>Sub Category:</b> {{node.name}}</span> 
              <!-- <span class="node-info" *ngIf="isSubCategoryNode(node)"><b>Description:</b> {{node.description ?? '---'}}</span>  -->
      
              <span *ngIf="isSubCategoryTwoNode(node)"><b>Second Sub Category:</b> {{node.name}}</span> 
              <!-- <span  class="node-info" *ngIf="isSubCategoryTwoNode(node)"><b>Description:</b> {{node.description ?? '---'}}</span>  -->
      
              <span *ngIf="isDriveReportNode(node)"><b>Report:</b> {{node.name}}</span> 
              <!-- <span class="node-info" *ngIf="isDriveReportNode(node)"><b>Description:</b> {{node.description ?? '---'}}</span>  -->
    
            </div>
           
            <!-- <span *ngIf="isDriveReportNode(node)">Second Sub Categories: {{node.name}}</span>  -->
            <div>
              <span><b>Modified Date:</b> {{node.updatedDate ?? node.createdDate | date}}</span>
              <button class="sortButton" *ngIf="isSortable(node)" mat-icon-button color="primary" (click)="sortNode(node)"   value="Submit" ><mat-icon>sort</mat-icon></button>
              <button class="sortButton" *ngIf="isEditable(node)"  mat-icon-button color="primary" (click)="openEditNodeDialog(node)" ><mat-icon>edit</mat-icon></button>
      
              <button class="addReportButton" (click)="addReport(node)" mat-icon-button color="primary"><mat-icon class="actionIcon">add</mat-icon></button>
              <button mat-icon-button color="primary" (click)="deleteNode(node)"><mat-icon class="delete">delete</mat-icon></button>
            </div>
          

          </div>

          
        <!-- There is inline padding applied to this div using styles.
            This padding value depends on the mat-icon-button width.  -->
        <div [class.example-tree-invisible]="!treeControl.isExpanded(node)"
            role="group">
          <ng-container matTreeNodeOutlet></ng-container>
      </div>
    </mat-nested-tree-node>
  </mat-tree>
</mat-card>

