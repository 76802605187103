<div  class="containerMegaMobile">

    <div class="tools">
        <mat-list role="list">
            <mat-list-item role="listitem" ><b>Hi, {{getName()}}</b></mat-list-item>
            <mat-divider></mat-divider>

<!-- 
            <mat-tree [dataSource]="dataSource1" [treeControl]="treeControl1">
              <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                <button mat-icon-button disabled></button>
                {{node.name}}
              </mat-tree-node>
              <mat-tree-node *matTreeNodeDef="let node;when: hasChild1" matTreeNodePadding>
                <button mat-icon-button matTreeNodeToggle
                        [attr.aria-label]="'Toggle ' + node.name">
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                  </mat-icon>
                </button>
                {{node.name}}
              </mat-tree-node>
            </mat-tree>
 -->


            <button style="margin: 5px 20px; font-size: 16px;" mat-button [matMenuTriggerFor]="menuu" (click)="$event.stopPropagation();"><i style="color: gold; " class="material-icons">star_rate</i>  Favorites<img style="margin-left: 5px;" src="../../../assets/images/Gallagherdropicon.svg" alt=""
              id="rightarrowofmenu"></button>
          <mat-menu #menuu="matMenu" xPosition="after">
              <div style="display: flex; justify-content: left;" *ngFor="let report of userService.favoriteReports; index as i" (mouseenter)="setReportVisible(i)" (mouseleave)="setReportNotVisible(i)">
                  <button  color="accent" (click)="navigateToReportView(report)" style="cursor: pointer; color: #2b5a80; width: 200px;"  mat-menu-item>{{report.name}}</button> 
                  <!-- <i  (click)="deletefavoriteReport(report)" style="line-height: 2!important; cursor: pointer;" class="material-icons">cancel</i> -->
                  <div matTooltip="Remove Report" matTooltipPosition="right" *ngIf="isVisible(report)" style="line-height: 2.7; margin-right: 5px;" (click)="deletefavoriteReport(report)"><b style="cursor: pointer; ">X</b></div>
              
                  <!-- <button matTooltip="Remove Report"  color="accent" (click)="deletefavoriteReport(report)" style="margin-right: 5px;"  mat-menu-item>X</button>  -->
                  </div>
          
                  <div style="display: flex; justify-content: left;"  >
                      <button  color="accent" (click)="navigateToManageFavorites()" style="cursor: pointer; color: #2b5a80; width: 200px;"  mat-menu-item><b>Manage Favorites</b></button> 
                      </div>
          </mat-menu>

          <mat-divider></mat-divider>

          <button style="margin: 5px 26px; font-size: 16px;" mat-button [matMenuTriggerFor]="menuuu" (click)="$event.stopPropagation();">Recent Items<img style="margin-left: 5px;" src="../../../assets/images/Gallagherdropicon.svg" alt=""
            id="rightarrowofmenu"></button>
        <mat-menu #menuuu="matMenu" xPosition="after">
            <div style="display: flex; justify-content: left;" *ngFor="let report of userService.visitReports; index as i" (mouseenter)="setReportVisible(i)" (mouseleave)="setReportNotVisible(i)">
                <button  color="accent" (click)="navigateToReportView(report)" style="cursor: pointer; color: #2b5a80; width: 300px;"  mat-menu-item><b>{{i+1}}.</b> {{report.name}}</button> 
                <!-- <i  (click)="deletefavoriteReport(report)" style="line-height: 2!important; cursor: pointer;" class="material-icons">cancel</i> -->
                <!-- <div matTooltip="Remove Report" matTooltipPosition="right" *ngIf="isVisible(report)" style="line-height: 2.7; margin-right: 5px;" (click)="deletefavoriteReport(report)"><b style="cursor: pointer; ">X</b></div> -->
            
                <!-- <button matTooltip="Remove Report"  color="accent" (click)="deletefavoriteReport(report)" style="margin-right: 5px;"  mat-menu-item>X</button>  -->
                </div>
        
                <div style="display: flex; justify-content: left;"  >
                    <button (click)="deleteReportVisits()"  color="accent" style="cursor: pointer; color: #2b5a80; width: 200px;"  mat-menu-item><b>Clear Recents</b></button> 
                    </div>
        </mat-menu>




          <mat-divider></mat-divider>


            <mat-list-item role="listitem"  style="cursor: pointer; padding: 0;"> <a mat-menu-item  target="_blank"  href="https://teams.microsoft.com/l/team/19%3aeFpJ2xNGPlXo2vUHbAVurkuBnU0v0AAiPPCUnd4p5do1%40thread.tacv2/conversations?groupId=3734acb6-368c-47bb-9e6e-d086fd3ea8c6&tenantId=6cacd170-f897-4b19-ac58-46a23307b80a">Support</a>
            </mat-list-item>
            <mat-divider></mat-divider>

            <mat-list-item role="listitem" style="cursor: pointer; padding: 0;" *ngIf="isAdmin()">  <a mat-menu-item  [routerLink]="['manage']">Manage</a> </mat-list-item>
            <mat-divider *ngIf="isAdmin()"></mat-divider>
      

            <mat-list-item role="listitem" style="cursor: pointer; padding: 0;"><a mat-menu-item [routerLink]="['']">Home</a></mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item role="listitem" style="cursor: pointer; padding: 0;"><a mat-menu-item (click)="logout()">Logout</a> </mat-list-item>
            <mat-divider></mat-divider>
        </mat-list>
    </div>

    <div class="megaMobile">


        <mat-card  class="main-card">
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree" #treeSelector>
              <!-- This is the tree node template for leaf nodes -->
              <!-- There is inline padding applied to this node using styles.
                This padding value depends on the mat-icon-button width. -->
              <mat-tree-node [ngClass]="{
                'subject-area-node': isSubjectAreaNode(node), 
                'category-node': isCategoryNode(node),
                'subcategory-node': isSubCategoryNode(node),
                'second-subcategory-node': isSubCategoryTwoNode(node),
                'report-node': isDriveReportNode(node),
                'add-node': isAddNewSubjectAreaNode(node)}"  *matTreeNodeDef="let node" matTreeNodeToggle>
                <div [ngClass]="{
                  'subject-area-node': isSubjectAreaNode(node), 
                  'category-node': isCategoryNode(node),
                  'subcategory-node': isSubCategoryNode(node),
                  'second-subcategory-node': isSubCategoryTwoNode(node),
                  'report-node': isDriveReportNode(node),
                  'add-node': isAddNewSubjectAreaNode(node)}" class="mat-tree-node"    >
          
                  
                    <div class="node-info" >
                      <img style="max-height: 40px;"  [src]="node.image" class="preview">
                      <span *ngIf="isSubjectAreaNode(node)"><b>Subject:</b> {{node.name}}</span> 
                      <span class="node-info" *ngIf="isSubjectAreaNode(node)"><b>Description:</b> {{node.description ?? '---'}}</span> 
                      <!-- <img style="max-height: 40px;"  [src]="node.safeResourceUrl" class="preview"> -->
                      
          
          
                      <span *ngIf="isCategoryNode(node)"><b>Category: </b>{{node.name}}</span> 
                      <!-- <span class="node-info" *ngIf="isCategoryNode(node)"><b>Description:</b> {{node.description ?? '---'}}</span>  -->
              
                      <span *ngIf="isSubCategoryNode(node)"><b>Sub Category:</b> {{node.name}}</span> 
                      <!-- <span class="node-info" *ngIf="isSubCategoryNode(node)"><b>Description:</b> {{node.description ?? '---'}}</span>  -->
              
                      <span *ngIf="isSubCategoryTwoNode(node)"><b>Second Sub Category:</b> {{node.name}}</span> 
                      <!-- <span class="node-info" *ngIf="isSubCategoryTwoNode(node)"><b>Description:</b> {{node.description ?? '---'}}</span>  -->
              
                      <span *ngIf="isDriveReportNode(node)" style="cursor: pointer;" (click)="navigateToReportView(node)"><b>Report:</b> {{node.name}}</span> 
                      <!-- <span  class="node-info" *ngIf="isDriveReportNode(node)"><b>Description:</b> {{node.description ?? '---'}}</span>  -->
          
                      <!-- <span *ngIf="isAddNewSubjectAreaNode(node)">Add New Subject Area</span> -->
          
                    </div>
                    
                    
                    <!-- <button class="addReportButton"  mat-raised-button color="primary"><mat-icon class="actionIcon">add</mat-icon> Add Report</button> -->
                    <!-- <div>
                      <span *ngIf="!isAddNewSubjectAreaNode(node)"><b>Modified Date:</b> {{node.updatedDate ?? node.createdDate | date}}</span>
                      <button class="sortButton" *ngIf="isSortable(node)" mat-icon-button color="primary" (click)="sortNode(node)"   value="Submit" ><mat-icon>sort</mat-icon></button>
                      <button class="sortButton"  *ngIf="isEditable(node)" mat-icon-button color="primary" (click)="openEditNodeDialog(node)"  ><mat-icon>edit</mat-icon></button>
                   
                      <button  class="addReportButton" (click)="addReport(node)" mat-icon-button color="primary" color="primary"><mat-icon [ngClass]="{'add-node-icon': isAddNewSubjectAreaNode(node)}"  class="actionIcon">add</mat-icon></button>
                      <button *ngIf="!isAddNewSubjectAreaNode(node)" mat-icon-button color="primary" (click)="deleteNode(node)"><mat-icon class="delete">delete</mat-icon></button>
                    </div> -->
          
                
          
                 
                </div>
               
               
              </mat-tree-node>
              <!-- This is the tree node template for expandable nodes -->
              <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                  <div [ngClass]="{
                    'subject-area-node': isSubjectAreaNode(node), 
                    'category-node': isCategoryNode(node),
                    'subcategory-node': isSubCategoryNode(node),
                    'second-subcategory-node': isSubCategoryTwoNode(node),
                    'report-node': isDriveReportNode(node)}" class="mat-tree-node" >
                   
          
                      <div>
                        <button mat-icon-button matTreeNodeToggle
                                [attr.aria-label]="'Toggle ' + node.name">
                          <mat-icon class="mat-icon-rtl-mirror">
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                          </mat-icon>
                        </button>
                        <img style="max-height: 40px;"  [src]="node.image" class="preview">
                        <span *ngIf="isSubjectAreaNode(node)"><b>Subject:</b> {{node.name}}</span> 
                        <!-- <img style="max-height: 40px;"  [src]="node.safeResourceUrl" class="preview"> -->
                        <!-- <span class="node-info" *ngIf="isSubjectAreaNode(node)"><b>Description:</b> {{node.description ?? '---'}}</span>  -->
                
                        <span *ngIf="isCategoryNode(node)"><b>Category: </b>{{node.name}}</span> 
                        <!-- <span class="node-info" *ngIf="isCategoryNode(node)"><b>Description:</b> {{node.description ?? '---'}}</span>  -->
                
                        <span *ngIf="isSubCategoryNode(node)"><b>Sub Category:</b> {{node.name}}</span> 
                        <!-- <span class="node-info" *ngIf="isSubCategoryNode(node)"><b>Description:</b> {{node.description ?? '---'}}</span>  -->
                
                        <span *ngIf="isSubCategoryTwoNode(node)"><b>Second Sub Category:</b> {{node.name}}</span> 
                        <!-- <span  class="node-info" *ngIf="isSubCategoryTwoNode(node)"><b>Description:</b> {{node.description ?? '---'}}</span>  -->
                
                        <span *ngIf="isDriveReportNode(node)" style="cursor: pointer;" (click)="navigateToReportView(node)"><b>Report:</b> {{node.name}}</span> 
                        <!-- <span class="node-info" *ngIf="isDriveReportNode(node)"><b>Description:</b> {{node.description ?? '---'}}</span>  -->


                        <!-- <div *ngIf="!report.externalUrl" (click)="navigateToReportView(report)">
                            {{report.name}}
                        </div>
                        <div *ngIf="report.externalUrl" (click)="goToLink(report.url)">
                            {{report.name}}
                        </div> -->
                      </div>
                     
                      <!-- <span *ngIf="isDriveReportNode(node)">Second Sub Categories: {{node.name}}</span>  -->
                      <!-- <div>
                        <span><b>Modified Date:</b> {{node.updatedDate ?? node.createdDate | date}}</span>
                        <button class="sortButton" *ngIf="isSortable(node)" mat-icon-button color="primary" (click)="sortNode(node)"   value="Submit" ><mat-icon>sort</mat-icon></button>
                        <button class="sortButton" *ngIf="isEditable(node)"  mat-icon-button color="primary" (click)="openEditNodeDialog(node)" ><mat-icon>edit</mat-icon></button>
                
                        <button class="addReportButton" (click)="addReport(node)" mat-icon-button color="primary"><mat-icon class="actionIcon">add</mat-icon></button>
                        <button mat-icon-button color="primary" (click)="deleteNode(node)"><mat-icon class="delete">delete</mat-icon></button>
                      </div> -->
                    
          
                    </div>
          
                    
                  <!-- There is inline padding applied to this div using styles.
                      This padding value depends on the mat-icon-button width.  -->
                  <div [class.example-tree-invisible]="!treeControl.isExpanded(node)"
                      role="group">
                    <ng-container matTreeNodeOutlet></ng-container>
                </div>
              </mat-nested-tree-node>
            </mat-tree>
          </mat-card>
          
    </div>
















    
  
</div>