
<div class="frameWindow">

  <!-- <div style="display: flex; flex-direction: row; justify-content: left;">
    <app-breadcrumb-viewer></app-breadcrumb-viewer>
  </div>  -->
    <!-- <div style="display: flex; flex-direction: row; justify-content: left;">
        <app-breadcrumb-viewer></app-breadcrumb-viewer>
    
        <div style="margin-left: 900px;"> 
          <i *ngIf="!isFavorited()" (click)="favoriteReport()" style="cursor: pointer; font-size: 35px;" class="material-icons">star_border</i>
          <i *ngIf="isFavorited()" style="color: gold; cursor: pointer; font-size: 35px;" (click)="deletefavoriteReport()" class="material-icons">star_rate</i>  
          <span  *ngIf="!isFavorited()">Favorite</span>
          <span  *ngIf="isFavorited()">Unfavorite</span>
        </div>
      
      </div> -->
    <iframe style="border: none; height: 100vh; margin-top: 100px;" [src]="safeUrl" title="Url Viewer"></iframe>
</div>
