import { NestedTreeControl } from '@angular/cdk/tree';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTreeNestedDataSource, MatTree, MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EditTreeNodeRequest } from 'src/app/models/TreeSortNode';
import { AdGroup } from 'src/app/models/adGroup';
import { AppConfig } from 'src/app/models/app.config';
import { DriveReport } from 'src/app/models/drive-report';
import { SnackbarModes } from 'src/app/models/enums/SnackbarModes';
import { SubjectArea } from 'src/app/models/subject-area';
import { ConfigService } from 'src/app/services/config.service';
import { HttpService } from 'src/app/services/http.service';
import { MegamenuService } from 'src/app/services/megamenu.service';
import { TreeHelperServiceService } from 'src/app/services/tree-helper-service.service';
import { UserAccountService } from 'src/app/services/user-account.service';
import { UserService } from 'src/app/services/user.service';
import { EditTreenodeModalComponent } from 'src/app/sharedV2/edit-treenode-modal/edit-treenode-modal.component';
import { ErrorSnackbarComponent } from 'src/app/sharedV2/snack-bar/fail-snackbar/error-snackbar/error-snackbar.component';
import { SnackbarComponent } from 'src/app/sharedV2/snack-bar/snackbar/snackbar.component';
import { UrlViewerServiceService } from 'src/app/sharedV2/url-viewer/url-viewer-service.service';
import { WarningModalComponent } from 'src/app/sharedV2/warning-modal/warning-modal.component';
import {FlatTreeControl} from '@angular/cdk/tree';

/**
 * Food data with nested structure.
 * Each node has a name and an optional list of children.
 */
interface FoodNode {
  name: string;
  children?: FoodNode[];
}


/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

interface FoodNode {
  name: string;
  children?: FoodNode[];
}

const TREE_DATA: FoodNode[] = [
  {
    name: 'Fruit',
    children: [{name: 'Apple'}, {name: 'Banana'}, {name: 'Fruit loops'}],
  },
  {
    name: 'Vegetables',
    children: [
      {
        name: 'Green',
        children: [{name: 'Broccoli'}, {name: 'Brussels sprouts'}],
      },
      {
        name: 'Orange',
        children: [{name: 'Pumpkins'}, {name: 'Carrots'}],
      },
    ],
  },
];
@Component({
  selector: 'app-drive-megamenu-mobile',
  templateUrl: './drive-megamenu-mobile.component.html',
  styleUrls: ['./drive-megamenu-mobile.component.scss']
})
export class DriveMegamenuMobileComponent implements OnInit {
  private _transformer = (node: FoodNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
    };
  };

  treeControl1 = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener1 = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource1 = new MatTreeFlatDataSource(this.treeControl1, this.treeFlattener1);



  hasChild1 = (_: number, node: ExampleFlatNode) => node.expandable;


  constructor(public userAccountService: UserAccountService,  public httpService: HttpService, private _sanitizer: DomSanitizer, @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, private broadcastService: MsalBroadcastService, 
  private authService: MsalService, private configService: ConfigService, private http: HttpClient, public megamenuService: MegamenuService, private _router: Router, private urlViewrService: UrlViewerServiceService
  , private router: Router, public userService: UserService,
  private _snackBar: MatSnackBar, private dialog: MatDialog, private treeHelper: TreeHelperServiceService) {  this.dataSource.data = TREE_DATA;}


  getName(): string {
    // this.setMegamenuSubscriptions();
    if(this.userAccountService?.account?.name) {
      return this.userAccountService?.account?.name;

    }
    return ''
    
  }

 
  
  logout() { // Add log out function here
    const config = this.configService.readConfig() as AppConfig;
    const webUrl = `${config.webUrl}`;
    this.authService.logoutRedirect({
      postLogoutRedirectUri: webUrl
    });
  }

















































  isAdmin() {
    return this.megamenuService.isAdmin();
   }
 
  handleNode(node: any) {
  }
  
  navigateToReportView(report: DriveReport) {
    if(report.workSpaceId && report.powerBiReportId) {
      this._router.navigate(['report-viewer', report.workSpaceId, report.powerBiReportId, report.id]).then(() => {
        window.location.reload();
        });
    } else {
      this.urlViewrService.setLatestUrl(report.url);
      this._router.navigate(['url-viewer']).then(() => {
        window.location.reload();
        });
    }
  
   
   }
  


  treeControl = new NestedTreeControl<any>(node => node.children);
  dataSource = new MatTreeNestedDataSource<FoodNode>();
  adGroups!: AdGroup[];
  selectedAdGroup!: AdGroup;
  subjectAreas: SubjectArea[] = [];
  treeData: FoodNode[] = [];
  isOrderingSubjectAreas = false;
  warningDialogRef!: MatDialogRef<WarningModalComponent>;
  sortableNodes: any[] = [];


  @ViewChild('treeSelector') tree!: MatTree<any>;
  @ViewChild('matRef') matRef!: MatSelect;

  ngOnInit(): void {

    this.checkStorageForData()
   
    //this.getAdGroups();
    }

    checkStorageForData() {
      const lastCacheDate = localStorage.getItem("MEGAMENU_SUBJECTAREAS_BYNAME_LAST_CACHE_DATE");
      if(lastCacheDate) {
        var startTime = new Date(lastCacheDate); 
        var endTime = new Date();
        var difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
        var resultInMinutes = Math.round(difference / 60000);
  
        if(resultInMinutes > 60) {
          this.setMegamenuSubscriptions();
          return;
        } else {
          const subjectAreasString = localStorage.getItem("MEGAMENU_SUBJECTAREAS_BYNAME_VALUE");

          const adlist = localStorage.getItem("USERS_AZURE_ADGROUPS") as string;
          this.megamenuService.userAzureAdGroups = JSON.parse(adlist) as string[]; 
          
          if(subjectAreasString) {
            this.httpService.megamenuLoading = false;
           this.subjectAreas = JSON.parse(subjectAreasString) as SubjectArea[];
           this.subjectAreas = this.sortList(this.subjectAreas);
           this.subjectAreas.forEach((subjectArea) => {
             subjectArea.safeResourceUrl = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' 
             + subjectArea.image);
            });
            this.megamenuService.userLatestSubjectAreasChange.next(this.subjectAreas);
           
            // if(this.subjectAreas) {
            //   this.selectedSubjectArea = this.subjectAreas[0];
            // }
            
          }
          this.populateTree();
          return;
        }
  
  
        }
        this.setMegamenuSubscriptions();
    }

    setMegamenuSubscriptions() {
   

      // TODO: Put all megamenu service work in  the mega menu service. Some functions are still in the general https services from early design.
      try {
        this.httpService.megaMenuSubjectAreasChange.subscribe(data => {
  
          this.subjectAreas = this.sortList(data);
  
          this.subjectAreas.forEach((subjectArea) => {
           subjectArea.safeResourceUrl = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' 
           + subjectArea.image);
          });
          this.populateTree();
          // if(this.subjectAreas) {
          //   this.selectedSubjectArea = this.subjectAreas[0];
          // }
           
        });
  
        this.megamenuService.userAssignedAdGroupsChange.subscribe(adGroupsList => {
          const adGroupsStringList: string[] = adGroupsList.map(x => x.displayName);
  
          this.megamenuService.getMegaMenuSubjectAreaForAdGroupNames(adGroupsStringList).subscribe(data => {
            this.subjectAreas = this.sortList(data);
  
            this.subjectAreas.forEach((subjectArea) => {
              subjectArea.safeResourceUrl = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' 
              + subjectArea.image);
             });
     
            //  if(this.subjectAreas) {
            //    this.selectedSubjectArea = this.subjectAreas[0];
            //  }
              
     
               this.httpService.megamenuLoading = false;
               this.populateTree();
          },
          err => {
            //this.userHasAcceess = false;
            this.httpService.megamenuLoading = false;
            console.log('HTTP Error', err)
          })
          
  
         
        });
        
        //this.httpService.getMegaMenuSubjectAreas()
      } catch (error: any) {
        // Prepare status message for Embed failure
     
        const displayMessage = `Failed to fetch config for report. Status: ${error.statusText} Status Code: ${error.status}`;
        console.error(displayMessage);
        return;
      }
    }

    getAdGroups() {
      try {

        // this.subjectAreas.push({id: 'add'} as SubjectArea)
        // this.dataSource.data = this.subjectAreas;
  
        this.httpService.getAdGroups('')
        .subscribe(adGroups => {
          this.adGroups = adGroups;

          //if adgroup was previously select, then reselect it. 
          if(this.selectedAdGroup) {
            const refreshedAdGroups = adGroups.filter(obj => {
              return obj.id === this.selectedAdGroup.id
            });

            
            const refreshedAdGroup = refreshedAdGroups[0];
           // this.populateTree(refreshedAdGroup);
            this.selectedAdGroup = refreshedAdGroup;
            this.getNewMegamenu(refreshedAdGroup.id);
          }
        });
  
      } catch (error: any) {
        const displayMessage = `Failed to fetch config for report. Status: ${error.statusText} Status Code: ${error.status}`;
        console.error(displayMessage);
        return;
      }
    }

    adGroupSelected(adGroup: AdGroup) {
      this.selectedAdGroup = adGroup;
    }

    getNewMegamenu(adGroupdId: string) {
      this.httpService.getMegaMenuSubjectAreas(adGroupdId);
    }

    populateTree() {
      
      this.subjectAreas.forEach((subjectArea) => {
       subjectArea.safeResourceUrl = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' 
       + subjectArea.image);

       subjectArea.isNew = false;

      // this.tr
       subjectArea.children = subjectArea.categories.sort((a,b) => a.sequence - b.sequence);

       subjectArea.categories.forEach(category => {
        category.children = category.driveReports.sort((a,b) => a.sequence - b.sequence);
        category.children = category.children.concat(category.subCategories.sort((a,b) => a.sequence - b.sequence))

        category.subCategories.forEach(subCategory => {
          subCategory.children = subCategory.driveReports.sort((a,b) => a.sequence - b.sequence);
          subCategory.children = subCategory.children.concat(subCategory.subCategoryTwos.sort((a,b) => a.sequence - b.sequence));

          subCategory.subCategoryTwos.forEach(subCategoryTwo => {
            subCategoryTwo.children = subCategoryTwo.driveReports.sort((a,b) => a.sequence - b.sequence);
          });
        });
        
      });
      });
      
      this.dataSource.data = this.subjectAreas;
    }

    populateTreeFromSA() {
      this.subjectAreas.forEach((subjectArea) => {
        subjectArea.safeResourceUrl = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' 
        + subjectArea.image);
 
        subjectArea.isNew = false;
 
        subjectArea.children = subjectArea.categories.sort((a,b) => a.sequence - b.sequence);
 
        subjectArea.categories.forEach(category => {
         category.children = category.driveReports.sort((a,b) => a.sequence - b.sequence);
         category.children = category.children.concat(category.subCategories.sort((a,b) => a.sequence - b.sequence))
 
         category.subCategories.forEach(subCategory => {
           subCategory.children = category.driveReports.sort((a,b) => a.sequence - b.sequence);
           subCategory.children = subCategory.children.concat(subCategory.subCategoryTwos.sort((a,b) => a.sequence - b.sequence));
 
           subCategory.subCategoryTwos.forEach(subCategoryTwo => {
             subCategoryTwo.children = subCategoryTwo.driveReports.sort((a,b) => a.sequence - b.sequence);
           });
         });
         
       });
       });
 
       this.subjectAreas.push({id: 'add'} as SubjectArea)
       this.dataSource.data = this.subjectAreas;
     
    }

  hasChild = (_: number, node: FoodNode) => !!node.children && node.children.length > 0;

  hasCategories = (_: number, node: SubjectArea) => !!node.categories && node.categories.length > 0;

  isSubjectAreaNode(node: any) {
    if(node.hasOwnProperty('categories'))
      return true;
    return false;
  }

  isCategoryNode(node: any) {
    if(node.hasOwnProperty('subCategories'))
      return true;
    return false;
  }
  
  isSubCategoryNode(node: any) {
    if(node.hasOwnProperty('subCategoryTwos'))
      return true;
    return false;
  }

  isSubCategoryTwoNode(node: any) {
    if(node.hasOwnProperty('subCategoryId') &&
    node.hasOwnProperty('driveReports')
    )
      return true;
    return false;
  }

  isDriveReportNode(node: any) {
    if(node.hasOwnProperty('powerBiReportId'))
      return true;
    return false;
  }

  isAddNewSubjectAreaNode(node: any) {
    if(node.id == 'add')
      return true;
    return false;
  }

  deleteNode(node: any) {
    
      if(this.isSubjectAreaNode(node))
        this.deleteSubjectArea(node.id, node.name);
      else if(this.isCategoryNode(node))
        this.deleteCategory(node.id, node.name);
      else if(this.isSubCategoryNode(node))
        this.deleteSubCategory(node.id, node.name);
      else if(this.isSubCategoryTwoNode(node))
        this.deleteSubCategoryTwo(node.id, node.name);
      else if(this.isDriveReportNode(node))
        this.deleteReport(node.id, node.name);

  }



  clearAdSelect() {
    this.matRef.options.forEach((data: MatOption) => data.deselect());
  }

  deleteAdGroup( )  { 
      this.warningDialogRef = this.dialog.open(WarningModalComponent, {
        autoFocus: true,
        width: '400px',
        disableClose: true,
    });

    this.warningDialogRef.componentInstance.promptMessage =
        `Are you sure you want to delete this AD Group '${this.selectedAdGroup.name}'?`;
    this.warningDialogRef.componentInstance.yesBtnLabel = 'Delete';
    this.warningDialogRef.componentInstance.noBtnLabel = 'Return To Section';

    this.warningDialogRef.componentInstance.yesClicked.subscribe(() => {
        this.deleteAdGroupConfirmed();
        this.dialog.closeAll();
    });

    this.warningDialogRef.componentInstance.noClicked.subscribe(() => {
        this.dialog.closeAll();
    });

    this.warningDialogRef.componentInstance.headerIconClose.subscribe(() => {
        this.dialog.closeAll();
  });


  }

  deleteAdGroupConfirmed( )  {
       

    // debugger;
    this.httpService.DeleteAdGroup(this.selectedAdGroup.id).subscribe(success => {
      
      if(success) {
        this.openSnackBar(`Success: Deleted AD Group ${this.selectedAdGroup.name}`, 'close', SnackbarModes.success);

        const adGroupsNew = this.adGroups.filter(( adg ) => {
             return adg.id !== this.selectedAdGroup.id;
          });

        this.adGroups = adGroupsNew;
        // this.adGroupSelected(this.adGroups[0]);

        this.dataSource.data = [];
        this.selectedAdGroup = {} as AdGroup;
        this.clearAdSelect();
      }
       
    });
  }


  deleteSubjectArea(subjectAreaId: string, name: string )  {
    this.httpService.DeleteSubjectArea(subjectAreaId).subscribe(success => {
      
      if(success){
        this.openSnackBar(`Success: Deleted Subject Area ${name}`, 'close', SnackbarModes.success);

       // this.subjectAreas = this.subjectAreas.filter(sa => sa.id !== subjectAreaId);
        this.removeIdFromAnyLevel(subjectAreaId);
      }
       
    });
  }

  deleteCategory(categoryId: string, name: string )  {
    this.httpService.DeleteCategory(categoryId).subscribe(success => {
      
      if(success){
        this.removeIdFromAnyLevel(categoryId);
        this.openSnackBar(`Success: Deleted Category ${name}`, 'close', SnackbarModes.success);
      }
     
    });
  }

  deleteSubCategory(subCategoryId: string, name: string )  {
    this.httpService.DeleteSubCategory(subCategoryId).subscribe(success => {
      
      if(success){
        this.removeIdFromAnyLevel(subCategoryId);
        this.openSnackBar(`Success: Deleted Subcategory ${name}`, 'close', SnackbarModes.success);
      }
       
    });
  }

  deleteSubCategoryTwo(subCategoryTwoId: string, name: string )  {
    this.httpService.DeleteSubCategoryTwo(subCategoryTwoId).subscribe(success => {

      if(success){
        this.removeIdFromAnyLevel(subCategoryTwoId);
        this.openSnackBar(`Success: Deleted Second Subcategory  ${name}`, 'close', SnackbarModes.success);
      }
     
    });
  }

  deleteReport(reportId: string, name: string )  {
    this.httpService.DeleteReport(reportId).subscribe(success => {
      
      if(success){
        this.removeIdFromAnyLevel(reportId);
        this.openSnackBar(`Success: Deleted Report ${name}`, 'close', SnackbarModes.success);
      }
      
    });
  }

  // Removing from the root levels of the tree can be quite complex.
  // This allows us to search the tree for any particular guid and remove by id.
  removeIdFromAnyLevel(id: string) {
    this.subjectAreas = this.subjectAreas?.filter(sa => sa.id !== id);

    this.subjectAreas?.forEach(sa => {
      sa.children = sa.children?.filter(c => c.id !== id);
      sa.children?.every(c => {
        if(!c.children)
          return false;
        c.children = c.children?.filter(sc => sc.id !== id);
        c.children?.every(sc => {
          if(!sc.children)
            return false;
          sc.children = sc?.children?.filter(sct => sct.id !== id);
          sc.children?.every(sct => {
            if(!sct.children)
              return false;
            sct.children = sct.children?.filter(r => r.id !== id);
            return true;
          })
          return true;
        })
        return true;
      })

    });
    //this.populateTreeFromSA();

    // this.dataSource = new MatTreeNestedDataSource<FoodNode>();
    // let _data = this.subjectAreas;
     this.dataSource.data = JSON.parse(JSON.stringify(this.subjectAreas)) as SubjectArea[];
    // this.dataSource.data = _data;
    // this.dataSource.data = this.dataSource.data.slice();
    //this.tree.renderNodeChanges(_data);
  }

  // addReport() {

  //   this.treeHelper.adGroupId = this.selectedAdGroup.id;
  //   this.treeHelper.subjectAreaId = this.sele
  //   this._router.navigate(['add-report']);
  // }
  navigateToAddReport() {
    this._router.navigate(['add-report', '', '']);
  }

  addReport(node: any) {
    let nodeType = '';
    if(this.isSubjectAreaNode(node))
      nodeType = 'SubjectArea';
    else if(this.isCategoryNode(node))
      nodeType = 'Category';
    else if(this.isSubCategoryNode(node))
      nodeType = 'SubCategory';
    else if(this.isSubCategoryTwoNode(node))
      nodeType = 'SubCategoryTwo';
    else if(this.isDriveReportNode(node))
      nodeType = 'Report';
    else {
      // AD is not a true node so its an exception case.
      nodeType = 'AdGroup'
      this._router.navigate(['add-report', nodeType, this.selectedAdGroup.id]);
      return;
    }
      

    
    this._router.navigate(['add-report', nodeType, node.id]);
  }

 
  openSnackBar(message: string, action: string, mode: number) {

   

    if(mode == SnackbarModes.success){
      this._snackBar.openFromComponent(SnackbarComponent, {
        duration: 3000,
        panelClass: 'snackbar-style',
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });
    } else if (mode == SnackbarModes.error) {
      this._snackBar.openFromComponent(ErrorSnackbarComponent, {
        duration: 3000,
        panelClass: 'snackbar-style',
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });
    }
    
  }
 
  subjectAreaOrdersUpdated(subjectAreasSorted: SubjectArea[]) {
    this.isOrderingSubjectAreas = false;
    this.getAdGroups();
     
    // this.subjectAreas = subjectAreasSorted;
    // this.populateTreeFromSA();
    // this.getNewMegamenu(this.selectedAdGroup.id);
  }

  nodeOrdersUpdated(nodesSorted: any[]) {
    this.isOrderingSubjectAreas = false;
    this.subjectAreas = nodesSorted;
    this.populateTreeFromSA();
    this.getNewMegamenu(this.selectedAdGroup.id);
  }

  showDragSortView() {
    this.isOrderingSubjectAreas = true;
  }

  sortNode(node?: any) {

    if(!node)
      this.sortableNodes = this.subjectAreas;
    else 
      this.sortableNodes = node.children;

      this.isOrderingSubjectAreas = true;

}

  sortList(subjectAreas: SubjectArea[]): SubjectArea[] {
    const subjectAreasSorted = subjectAreas.sort((a, b) => (a.sequence > b.sequence) ? 1 : -1);
    return subjectAreasSorted;
  }

  isSortable(node: any) {
    const notAReportNode = !this.isDriveReportNode(node);
    const hasMoreThanSingleChild = node.children && node.children.length > 1;
    return notAReportNode && hasMoreThanSingleChild;
  }

  isEditable(node: any) {
    if(this.isSubjectAreaNode(node) || this.isCategoryNode(node) || this.isSubCategoryNode(node) || this.isSubCategoryTwoNode(node) || this.isDriveReportNode(node))
      return true;

    return false;
  }


  openEditNodeDialog(node: any) {
    const dialogRef = this.dialog.open(EditTreenodeModalComponent, {
        width: '420px',
        height: '615px',
        // panelClass: 'full-screen-modal'
        data: node
      });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        let nodeType = '';
        if(this.isSubjectAreaNode(node))
          nodeType = 'SubjectArea';
        else if(this.isCategoryNode(node))
          nodeType = 'Category';
        else if(this.isSubCategoryNode(node))
          nodeType = 'SubCategory';
        else if(this.isSubCategoryTwoNode(node))
          nodeType = 'SubCategoryTwo';
        else if(this.isDriveReportNode(node))
          nodeType = 'Report';

        const request: EditTreeNodeRequest = {id: node.id, name: result.name, description: result.description, externalUrl: result.externalUrl, nodeType: nodeType, webUrl: result.url, tags: result.tags, image: result.image} as EditTreeNodeRequest;
        this.treeHelper.editNode(request).subscribe(x => {
          this.openSnackBar(`Success: Edited ${nodeType}`, 'close', SnackbarModes.success);
          this.getAdGroups();
        });

         
      }
      
    });
  }




  
  deletefavoriteReport(report: DriveReport) {
    this.userService.DeleteReportFavorites(report.id).subscribe(x => {
      this.userService.removeFromFavoritesListLocal(report.id);
    })
  }

  deleteReportVisits() {
    this.userService.DeleteReportVisits().subscribe(x => {
      this.userService.removeFromVisitListLocal();
    })
  }


  isVisible(report: DriveReport) {
    return report.isVisibleInFavs;
  }

  setReportVisible(index: number) {
    this.userService.favoriteReports[index].isVisibleInFavs = true;
    
  }

  setReportNotVisible(index: number) {
    this.userService.favoriteReports[index].isVisibleInFavs = false;
  }

  navigateToManageFavorites() {
    this.router.navigate(['favorite-manager']);
  }

  setHeaderHoveredMegamenu() {
    this.megamenuService.headerHoveredLast = true;
  }

  setHeaderExitedMegamenu() {
    this.megamenuService.headerHoveredLast = false;
  }

  openedFavMenu() {
    // this.megamenuService.isInteractable = false;
    this.megamenuService.headerHoveredLast = true;
  }

  
  closedFavMenu() {
    this.megamenuService.headerHoveredLast = false;
  }


}
