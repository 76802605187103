import { Component, Inject, inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {LiveAnnouncer} from '@angular/cdk/a11y';

@Component({
  selector: 'app-edit-treenode-modal',
  templateUrl: './edit-treenode-modal.component.html',
  styleUrls: ['./edit-treenode-modal.component.scss']
})
export class EditTreenodeModalComponent {

constructor(public dialogRef: MatDialogRef<EditTreenodeModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

tags : string[] = [];
ngOnInit(): void {
  console.log(this.data,'data for edit modal=');
  this.form.controls.name.setValue(this.data?.name);
  this.form.controls.description.setValue(this.data?.description);
  this.form.controls.externalUrl.setValue(this.data?.externalUrl);
  this.form.controls.url.setValue(this.data?.url);
  this.form.controls.image.setValue(this.data?.image)
  this.tempImageBase64 = this.data?.image
  // this.form.controls.externalUrl.setValue(this.data?.tags);
  

  if(this.data?.tags) {
    this.tags = (this.data?.tags as string).split(',');
  }
  


}

  form = new FormGroup({
    name: new FormControl(''),
    description: new FormControl(''),
    externalUrl: new FormControl(false),
    url: new FormControl(''),
    tags: new FormControl(''),
    image: new FormControl(''),
  });

  save() {
    this.form.controls.tags.setValue(this.tags.join(", "));
    this.form.controls.image.setValue(this.tempImageBase64);

    debugger;
    this.dialogRef.close(this.form.value);
  }

  cancel(){
    this.dialogRef.close();
  }


  keywords = ['angular', 'how-to', 'tutorial', 'accessibility'];
  formControl = new FormControl(['angular']);

  announcer = inject(LiveAnnouncer);
  removeTag(tag: string) {
    const index = this.tags.indexOf(tag);
    if (index >= 0) {
      this.tags.splice(index, 1);

      this.announcer.announce(`removed ${tag}`);
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our keyword
    if (value) {
      this.tags.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  tempImageBase64: string = '';

  updateImageBase64String(base64String: any) {
    this.tempImageBase64 = base64String;
    this.form.controls.image.setValue(base64String);
    this.form.controls.image.markAsDirty();
    }


}
