<div *ngFor="let progressInfo of progressInfos">
  <span>{{ progressInfo.fileName }}</span>
  <mat-toolbar class="progress-bar">
    <mat-progress-bar
      color="accent"
      [value]="progressInfo.value"
    ></mat-progress-bar>
    <span class="progress">{{ progressInfo.value }}%</span>
  </mat-toolbar>
</div>



<div>
  <div>
    <a class="uploadText" (click)="uploadFileBoxClick()" >
      <mat-icon>attach_file</mat-icon> Attach Icon for subject area
    </a>
  </div>
  <!-- <mat-toolbar>
    <input matInput [value]="selectedFileNames.length ? selectedFileNames : 'Select Images'" />

    <button
      mat-flat-button
      color="primary"
      [disabled]="!selectedFiles"
      (click)="uploadFiles()"
    >
      Upload
    </button>
  </mat-toolbar> -->

  <input
    type="file"
    id="fileInput"
    name="fileInput"
    accept="image/*" multiple 
    (change)="selectFiles($event)"
    #uploadFileBox
  />
</div>

<!-- 
<mat-form-field appearance="outline">
  <div>

    <mat-toolbar>
      <input matInput [value]="selectedFileNames.length ? selectedFileNames : 'Select Images'" />

      <button
        mat-flat-button
        color="primary"
        [disabled]="!selectedFiles"
        (click)="uploadFiles()"
      >
        Upload
      </button>
    </mat-toolbar>

    <input
      type="file"
      id="fileInput"
      name="fileInput"
      accept="image/*" multiple 
      (change)="selectFiles($event)"
    />
  </div>
</mat-form-field> -->

<!-- <div>
  <img *ngFor='let preview of previews' [src]="preview" class="preview">
</div> -->

<div *ngIf="message.length" class="message">
  <ul *ngFor="let msg of message; let i = index">
    <li>{{ msg }}</li>
  </ul>
</div>

<!-- <mat-card class="list-card">
  <mat-card-header>
    <mat-card-title>List of Images</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-list role="list">
      <mat-list-item role="listitem" *ngFor="let image of imageInfos | async" class="list-item">
        <p matLine><a href="{{ image.url }}">{{ image.name }}</a></p>
        <img src="{{ image.url }}" alt="{{ image.name }}" height="50px"/>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card> -->
