import { Component } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { ReportManagerModalComponent } from '../report-manager-modal/report-manager-modal.component';

@Component({
  selector: 'app-login-header',
  templateUrl: './login-header.component.html',
  styleUrls: ['./login-header.component.scss']
})
export class LoginHeaderComponent {
  constructor(public dialog: MatDialog) {}

  ngOnInit(){
   this.openDialog();
  }

  openDialog() {

  }
}
